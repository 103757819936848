import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import styles from './Features.module.css'

/* --- IMAGENS --- */
import image1 from '../../images/scormIcon.png';
import image2 from '../../images/subtitleIcon.png';
import image3 from '../../images/videointeractivIcon.png';
import image4 from '../../images/translateIcon.png';
import image5 from '../../images/quizIcon.png';
import image6 from '../../images/exportIcon.png';

const Features = ({ userSetLanguage }) => {

  const { t } = useTranslation(['features']);
  const { user } = useAuthValue();
  const { preferenceLanguage } = useLanguageValue();
  /* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
  const fadeInElements = useRef([]);
  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState(userSetLanguage);

  const navigate = useNavigate();

  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
  //const userLanguage = "pt"

  const goRegister = () => {
    window.scrollTo(0, 0);
    navigate("/register")
  }

  const goPrices = () => {
    navigate("/catalog")
  }

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  /* --- FIM DA ANIMAÇÃO POR SCROLL --- */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  useEffect(() => {
    user && preferenceLanguage && setPreferenceBrowserLanguage(preferenceLanguage)
  }, [preferenceLanguage, user])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <div className={`${styles.bg}`}>

      {/* Section SCORM */}
      <div className={`row justify-content-center align-items-center mt-5 ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image1} alt="Crie perguntas e quizzes" />
            <h2>{t('features:home.Converta rapidamente seu vídeo ou PowerPoint em SCORM')}</h2>
          </div>
        </div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Escolha um modelo e inclua seu logotipo')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <div className="d-flex flex-column align-items-center">
            <span>{t('features:home.Crie um quiz curto ou avaliação final usando IA')}</span>

          </div>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <div className="d-flex flex-column align-items-center">
            <span>{t('features:home.Exporte seu projeto em SCORM 1.2 ou 2004')}</span>
          </div>
        </div>
      </div>

      {/* Section Subtitle */}
      <div className={`row justify-content-center align-items-center ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image2} alt="Incorpore conteúdo rico em seus quizzes" />
            <h2>{t('features:home.Gere legendas para seu vídeo automaticamente usando IA')}</h2>
          </div>
        </div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Gere legendas sincronizadas para seu vídeo rapidamente')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <div className="d-flex flex-column align-items-center">
            <span>{t('features:home.Traduza as legendas do seu vídeo para outros idiomas em minutos')}</span>
          </div>
        </div>

        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Crie quizzes ou uma avaliação final com base no conteúdo do seu vídeo')}</p>
        </div>
      </div>

      {/* Section Interactive Videos */}
      <div className={`row justify-content-center align-items-center ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image3} alt="Crie vídeos interativos" />
            <h2>{t('features:home.Crie vídeos interativos')}</h2>
          </div>
        </div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Adicione elementos interativos ao seu vídeo')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Gere quizzes ou telas de resumo sobre o vídeo usando IA')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Use IA para gerar casos conceituais com base no conteúdo do vídeo')}</p>
        </div>
      </div>

      {/* Section 10 languages */}
      <div className={`row justify-content-center align-items-center ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image4} alt="Gere conteúdo ou traduza seus projetos para 10 idiomas" />
            <h2>{t('features:home.Gere conteúdo ou traduza seus projetos para 10 idiomas')}</h2>
          </div>
        </div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p className={`tooltip-term" data-tooltip="term1`}><span>{t('features:home.Crie perguntas para quizzes ou avaliações em 10 idiomas diferentes')} </span>
            <OverlayTrigger placement="top" overlay={<Tooltip className={`${styles.tooltipCustom}`}><strong>{t('features:home.Idiomas atualmente disponíveis no QuizAI')}</strong> {t('features:home.inglês, espanhol, italiano, português, francês, alemão, russo, coreano, chinês e japonês')}</Tooltip>}>
              <span className={`${styles.tooltip_term}`}>{t('features:home.10 idiomas diferentes')}</span></OverlayTrigger>
          </p>
        </div>

        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Use IA para traduzir rapidamente as legendas ou perguntas do seu vídeo para outros idiomas')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Exporte o projeto para cada idioma como um pacote SCORM separado')}</p>
        </div>
      </div>

      {/* Section Quizes */}
      <div className={`row justify-content-center align-items-center ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image5} alt="Crie perguntas e quizzes" />
            <h2>{t('features:home.Crie perguntas e quizzes a partir de diferentes fontes de conteúdo')}</h2>
          </div>
        </div>
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Digite um assunto e escolha seus principais tópicos para gerar perguntas')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <div className="d-flex flex-column align-items-center">
            <span>{t('features:home.Revise as perguntas criadas e adicione novas manualmente')}</span>

          </div>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <div className="d-flex flex-column align-items-center">
            <span>{t('features:home.Envie seu arquivo de vídeo ou PPT e crie perguntas com base no seu conteúdo')}</span>
          </div>
        </div>
      </div >

      {/* Section Export */}
      <div className={`row justify-content-center align-items-center ${styles.etapas} ${styles.hideFadeIn}`} style={{ maxWidth: '1024px' }} ref={el => fadeInElements.current.push(el)}>
        <div className={`col-12 text-center`}>
          <div className={`${styles.circle_image}`}>
            <img src={image6} alt="Múltiplas opções de exportação" />
            <h2>{t('features:home.Múltiplas opções de exportação')}</h2>
          </div>
        </div>
        
        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.SCORM 1.2 e 2004')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.HTML')}</p>
        </div>
        <div className={`${styles.d_none} ${styles.d_sm_none} ${styles.d_md_block}`}><hr className={`${styles.vertical} col-auto `} /></div>
        <div className={`${styles.d_sm_block} ${styles.d_md_none} ${styles.p_2}`}><hr /></div>

        <div className={`col-12 ${styles.col_md_3} text-center`}>
          <p>{t('features:home.Word, formato Aiken (para Moodle) e formato GIFT')}</p>
        </div>
      </div>

      <section className={`${styles.secao} ${styles.botoesChamadas} row justify-content-center align-items-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
        <div className={`${styles.botoes} col-12 text-center `}>
          <div className={`d-flex flex-column flex-md-row justify-content-center align-items-center`}>
            <a href="#" onClick={() => { goRegister() }} className={`${styles.btn} ${styles.btn_primary} mb-3 mb-md-0 me-md-3`}>{t('features:home.Comece agora')}</a>
            <span className={`mb-3 mb-md-0`}> {t('features:home.ou')} </span>
            <a href="#" onClick={() => { goPrices() }} className={`${styles.btn} ${styles.btn_other} mt-3 mt-md-0 ms-md-3`}>{t('features:home.Conheça nossos planos')}</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Features