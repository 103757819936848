import styles from './MyProjects.module.css'
import { format } from 'date-fns';
import { pt, es, enUS, id } from 'date-fns/locale';
import ProjectSetup from '../../components/ProjectSetup';
import { NavLink } from 'react-router-dom';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//hooks
import { useNavigate, useLocation, json } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useDeleteDocument } from '../../hooks/useDeleteDocument';
import { useInsertDocument } from '../../hooks/useInsertDocument';
import { useUpdateDocument } from '../../hooks/useUpdateDocument';
import { useAuthentication } from '../../hooks/useAuthentications';
import { useAskChatGPT } from '../../hooks/useAskChatGPT';
import { useCheckLicenseLimits } from '../../hooks/useCheckLicenseLimits';
import { storage } from "../../firebase/config";

//context
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';

//components
import UpdateAssessment from '../assessment/UpdateAssessment';
import DropdownMenu from '../../components/DropdownMenu';
import DialogBox from '../../components/DialogBox';
import StartCarousel from '../../components/StartCarousel';
import ProjectPreview from '../../components/ProjectPreview';

//firebase
import { db } from '../../firebase/config';
import { Timestamp, doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, deleteObject, listAll, getMetadata } from "firebase/storage";

//Images
import foldericon from '../../images/folder.png';
import assessment from '../../images/assessment.png';
import cases from '../../images/cases.png';
import menuV from '../../images/menuV.svg';
import packageicon from '../../images/package.png';
import ppt from '../../images/ppt.png';
import texticon from '../../images/text.png';
import videoicon from '../../images/video.png';

const MyProjects = ({ userSetLanguage, hideMenuItem }) => {

  const location = useLocation();
  const folderValue = location.state ? location.state.projectFolder : "Folder";

  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("Folder");
  const [actualFolder, setActualFolder] = useState(folderValue);
  const [folderChange, setFolderChange] = useState("");
  const [createProject, setCreateProject] = useState(false);
  const [updateProject, setUpdateProject] = useState();
  const [createFolder, setCreateFolder] = useState(false);
  const [projectInfos, setProjectInfos] = useState({});
  const [folderName, setFolderName] = useState("");
  const [userName, setUserName] = useState("");
  const [showProject, setShowProject] = useState(false);
  const [controllerDivProject, setControllerDivProject] = useState("small")
  const [showButtons, setShowButtons] = useState(true);
  const [userDomainPlan, setUserDomainPlan] = useState("");
  const [search, setSearch] = useState();
  const [idProject, setIdProject] = useState("");
  const [msgDialog, setMsgDialog] = useState("");
  const [optionsDialog, setOptionsDialog] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogFolder, setOpenDialogFolder] = useState(false);
  const [openDialogRename, setOpenDialogRename] = useState(false);
  const [helloMessage, setHelloMessage] = useState("");
  const [key, setKey] = useState(0);
  const [updateAssessment, setUpdateAssessment] = useState(false);
  const [formattedPlan, setFormattedPlan] = useState("");
  const [pageTexts, setPageTexts] = useState([]);
  const [itemsDrop, setItemsDrop] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trialExpired, setTrialExpired] = useState(false);
  const [failedPayment, setFailedPayment] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [firstAccess, setFirstAccess] = useState(false)
  const [storageVideoSize, setStorageVideoSize] = useState(0);
  const [storagePPtSize, setStoragePPtSize] = useState(0);
  const [projectType, setProjectType] = useState("");
  const [updateSize, setUpdateSize] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [foldersMove, setFoldersMove] = useState([]);
  const [projectSelected, setProjectSelected] = useState("");
  const [visibleMenuProjectId, setVisibleMenuProjectId] = useState(null);
  const [visibleMenuFolderId, setVisibleMenuFolderId] = useState(null);
  const [projectCount, setProjectCount] = useState(0);
  const [foldersCount, setFoldersCount] = useState(0);
  const [limitAvaliable, setLimitAvaliable] = useState(true);
  const [domainActive, setDomainActive] = useState(true);
  const [domainStatus, setDomainStatus] = useState("")
  const [showHint1, setShowHint1] = useState(false);
  const [showHint2, setShowHint2] = useState(false);
  const [destinyLanguage, setDestinyLanguage] = useState("");
  const [projectAssessment, setProjectAssessment] = useState([]);
  const [projectSubtitles, setProjectSubtitles] = useState([]);
  const [projectIncludeContent, setProjectIncludeContent] = useState(false);
  const [projectIncludeCase, setProjectIncludeCase] = useState(false);
  const [projectContentGenerated, setProjectContentGenerated] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectMenu, setProjectMenu] = useState([]);
  const [projectPptText, setProjectPptText] = useState("");
  const [projectVideoText, setProjectVideoText] = useState("");
  const [projectLanguage, setProjectLanguage] = useState("");
  const [projectSubtitleLanguage, setProjectSubtitleLanguage] = useState("");
  const [projectClonedIds, setProjectClonedIds] = useState([]);
  const [projectIdCloned, setProjectIdCloned] = useState("");
  const [projectSize, setProjectSize] = useState(0.1);
  const [projectInteraction, setProjectInteraction] = useState([]);

  const [translatingProject, setTranslatingProject] = useState(false);
  const [objectTanslated, setObjectTranslated] = useState({});
  const [errorTranslating, setErrorTranslating] = useState("");
  const [errorDuplicatingDoc, setErroruplicatingDoc] = useState("");
  const [duplicateProject, setDuplicateProject] = useState(false);
  const [deletingProject, setDeletingProject] = useState(false);

  const { user } = useAuthValue();
  const { t } = useTranslation(['myprojects']);
  const { preferenceLanguage } = useLanguageValue();
  const { dataFetch, pptVideoInfos, checkSubscriptionStatus, checkDomainProjectsLimit, checkStorageLimit } = useCheckLicenseLimits(user.uid);

  //Actions

  const [action, setAction] = useState("");

  const [userId, setUserId] = useState(user.uid);

  const { document: userDoc, loadingUserDoc, errorUserDoc } = useFetchDocument("Users", user.uid)
  const { documents: projects, loadingProjects, error } = useFetchProjects(folder, search, user.uid);
  const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", userId);
  const { handleOpenAiApi, returnGPT, textTranslated, loadingGPT, errorGPT, generateGPT } = useAskChatGPT();

  const navigate = useNavigate();
  const projectTranslate = {};

  const { insertDocument, responseInsert } = useInsertDocument("Domains");
  const { updateDocument, responseUpdate } = useUpdateDocument("Domains");

  const inputRef = useRef(null);

  let visibleProjects;
  let lastVisibleProjectId;

  let hasFolder = false;
  let counterFolder = 0;
  let localeDate = pt;
  let shownProjectsCount = 0;
  let shownFoldersCount = 0;
  let totalObjects = 0;
  let isPair = false;

  const hideUpdateAssessment = () => {
    window.scrollTo(0, 0);
    setUpdateAssessment(false);
    setShowButtons(true);
    setShowProject(false)
    setProjectInfos({});
  }

  const hideCardFolderProject = () => {
    window.scrollTo(0, 0);
    setUpdateAssessment(false);
    setCreateProject(false);
    setShowButtons(true);
    setCreateFolder(false);
    setShowProject(false)
    const folderProjectElement = document.querySelector('.folderproject');
    if (folderProjectElement) {
      folderProjectElement.classList.add('d-none');
    }
    setControllerDivProject("small");
  }

  const handleCreateFolder = async (e) => {
    if (Array.from(folders.values()).includes(folderName)) {
      e.preventDefault();
      setAction("close_rename_folder")
      return
    } else {
      setCreateFolder(false);
      const folderNumber = counterFolder + 1;
      const newDocument = {
        folder_name: [folderName, ...folders],
        counter: folderNumber,
        domain: user.uid,
        id: "folder"
      }
      hideCardFolderProject();
      setFolderName("");
      window.scrollTo(0, 0);
      hasFolder
        ? await updateDocument(newDocument)
        : await insertDocument(newDocument);
    }
  }

  const handleChangeDropdown = (a, project, folder) => {
    if (trialExpired || failedPayment) {
      setShowImage(true)
      setMsgDialog(t('myprojects:messages.plano Trial expirou'));
      setOptionsDialog(`SAIBA MAIS`)
      return
    }

    if (a === "translate_project" && !limitAvaliable) {
      if (!limitAvaliable) {
        setMsgDialog(t('myprojects:messages.Você já atingiu o limite de projetos do mês'))
        setOptionsDialog(["Ok"])
        setOpenDialog(true)
        setAction("close_dialog_box")
        return
      }
    }

    setAction(a);
    if (project) {
      setProjectName(project.project_name)
      setIdProject(project.id)
      setProjectType(project.include_slides ? "ppt" : project.include_video ? "video" : "")
      setProjectSelected((project.include_slides || project.include_video) ? "file" : project.include_content ? "content" : "assessment")
      //item para tradução
      setProjectAssessment(project.assessment)
      setProjectSubtitles(project.subtitle)
      setProjectSubtitleLanguage(project.subtitle_language)
      setProjectIncludeContent(project.include_content)
      setProjectIncludeCase(project.include_case)
      setProjectContentGenerated(project.content_generated)
      setProjectLanguage(project.project_language)
      setProjectPptText(project.ppt_text)
      setProjectVideoText(project.video_text)
      setProjectMenu(project.menu_items)
      setProjectIdCloned(project.cloned_id || "")
      setProjectSize(project.project_size || 0.1)
      setProjectInteraction(project.video_interaction || [])
      if (project.cloned_id) {
        checkProjectClonedIds(project.cloned_id)
      } else {
        setProjectClonedIds(project.cloned_projects || [])
      }
    } else if (folder) {
      setFolderChange(folder)
    }
  }

  const checkProjectClonedIds = async (id) => {

    try {
      const originalDocRef = doc(db, "Domains", userId, "Folder", id);
      const originalDocSnapshot = await getDoc(originalDocRef);

      if (originalDocSnapshot.exists()) {
        const docData = originalDocSnapshot.data();

        setProjectClonedIds(docData.cloned_projects || []);

      } else {
        throw new Error('Documento original não encontrado.');
      }
    } catch (err) {
      setErroruplicatingDoc(err.message);
      console.error('Erro ao duplicar o documento:', err);
    }

  }


  const handleDeleteFolder = async () => {
    const folderIndex = Array.from(folders.values()).indexOf(folderChange);
    folders.splice(folderIndex, 1);
    const newFolders = folders;
    const newDocument = {
      id: "folder",
      domain: user.uid,
      folder_name: newFolders,
    }
    const res = await updateDocument(newDocument);
    projects.map(async (project) => {
      if (project.folder === folderChange) {
        const newDocument = {
          id: project.id,
          domain: user.uid,
          project_active: false,
          deleted_by: user.uid,
          deletion_date: Timestamp.now()
        }
        const res = await updateDocument(newDocument);
      }
    })
    setOpenDialogDelete(false);
    setAction("");
    setFolderChange("")
    setDeletingProject(false)
  }

  const handleChangeFolder = (f) => {
    setCreateFolder(false);
    setCreateProject(false);
    setActualFolder(f);
  }

  const actionCloseDialogBox = (f, option) => {
    setAction("");
    setOpenDialog(false);
    setMsgDialog("")
    setOptionsDialog([])
    if (option === "LEARN MORE" || option === "SAIBA MAIS" || option === "SEPA MAS") {
      navigate("/catalog")
    }
  }

  const actionDelete = async (confirmed) => {
    setOpenDialogDelete(false);
    setDeletingProject(true);

    const res = confirmed && (folderChange
      ? handleDeleteFolder()
      : updateClonedProjects(idProject))
    setAction("");
    setMsgDialog("");
  }

  const updateClonedProjects = async (idprojectdeleted) => {
    const newClonedProjects = projectClonedIds.filter((id) => id !== idprojectdeleted)

    const newCloned = {
      id: projectIdCloned ? projectIdCloned : idProject,
      domain: userId,
      cloned_projects: newClonedProjects
    }
    const res = await updateDocument(newCloned)

    if (projectSize > 0.1) {
      const idFisrtClonedProject = newClonedProjects[0]
      const newProjectSize = {
        id: idFisrtClonedProject,
        domain: userId,
        project_size: projectSize
      }

      const res = await updateDocument(newProjectSize)
    }

    var deletefiles = false
    if (newClonedProjects.length === 0) {
      deletefiles = true
    }
    const newDocument = {
      id: idProject,
      domain: user.uid,
      project_active: false,
      deleted_by: user.uid,
      deletion_date: Timestamp.now()
    }
    var idprojectdelete = projectIdCloned ? projectIdCloned : idProject
    const resdelete = await deleteFiles(newDocument, deletefiles, idprojectdelete)
  }

  useEffect(() => {
    projectInteraction && console.log("projectInteraction: " + projectInteraction)
  }, [projectInteraction])


  const deleteFiles = async (newDocument, deletefiles, idprojectdelete) => {

    const folderFile = projectType
    const path = `${folderFile}/${userId}/${idprojectdelete}`
    await updateDocument(newDocument)
    if (deletefiles) {
      const totalDeleteSizeInBytes = await getFolderSize(path)
      const totalDeleteSizeInKB = totalDeleteSizeInBytes / 1024
      const totalDeleteSizeInMB = totalDeleteSizeInKB / 1024
      await deleteFolderContents(path)
      if (projectType === "ppt") {
        const newPptSize = storagePPtSize - totalDeleteSizeInMB
        setStoragePPtSize(newPptSize)
        setUpdateSize(true)
      } else if (projectType === "video") {
        const newVideoSize = storageVideoSize - totalDeleteSizeInMB
        setStorageVideoSize(newVideoSize)
        setUpdateSize(true)
      }
    } else {
      setDeletingProject(false)
    }
  }

  const actionMoveFolder = async (folder, cancel) => {
    setOpenDialogFolder(false);
    setMsgDialog("");
    const newDocument = {
      id: idProject,
      domain: user.uid,
      folder: folder,
    }
    const res = !cancel && await updateDocument(newDocument);
    setAction("");
  }

  const actionRename = async (newName, cancel) => {
    setOpenDialogRename(false);
    setAction("");
    setMsgDialog("");
    let newDocument
    if (!newName) {
      return
    }
    if (!cancel && folderChange) {
      if (Array.from(folders.values()).includes(newName.trim())) {
        setAction("close_rename_folder")
        return
      }
      projects.map(async (project) => {
        if (project.folder === folderChange) {
          newDocument = {
            id: project.id,
            domain: user.uid,
            folder: newName,
          }
          const res = await updateDocument(newDocument);
        }
        setFolderChange()
      })
      const folderIndex = Array.from(folders.values()).indexOf(folderChange);
      folders[folderIndex] = newName;
      newDocument = {
        id: "folder",
        domain: user.uid,
        folder_name: folders,
      }
    } else if (!cancel && !folderChange) {
      const hasFileName = projects.some(project => project.project_name === newName.trim() && project.project_active);
      if (hasFileName) {
        setMsgDialog(t('myprojects:messages.projeto já existe', {
          folderName: folderName,
          interpolation: { prefix: '${', suffix: '}' }
        }));
        //setOpenDialog(true);
        setOptionsDialog(["Ok"])
        return
      }
      newDocument = {
        id: idProject,
        domain: user.uid,
        project_name: newName,
      }
    }
    const res = await updateDocument(newDocument);
  }

  const actionEditProject = () => {
    setShowProject(true)
  }

  const actionTranslate = () => {
    setAction("translate_project")
    setOptionsDialog(['Cancelar', 'Ok'])
    setOpenDialog(true)
  }

  const checkProjectLanguage = (language) => {
    switch (language) {
      case "Portuguese":
        return "Pt";
        break;
      case "English":
        return "En";
        break;
      case "Spanish":
        return "Sp";
        break;
      case "French":
        return "Fr";
        break;
      case "German":
        return "Gr";
        break;
      case "Italian":
        return "It";
        break;
      case "Mandarin":
        return "Md";
        break;
      case "Russian":
        return "Ru";
        break;
      case "Japanese":
        return "Jp";
        break;
      case "Korean":
        return "Ko";
        break;
      default:
        return "En";
        break;
    }
  }

  const getFolderSize = async (folderPath) => {
    const folderRef = ref(storage, folderPath);
    let totalSize = 0;

    try {
      const files = await listAll(folderRef);

      for (const item of files.items) {
        try {
          const metadata = await getMetadata(item);
          totalSize += metadata.size;
        } catch (error) {
          console.error('Error getting metadata for file:', error.message);
        }
      }
    } catch (error) {
      console.error('Error listing files in folder:', error.message);
      return null;
    }

    return totalSize;
  };

  const deleteFolderContents = async (path) => {

    const folderRef = ref(storage, path);
    const files = await listAll(folderRef);
    const deletePromises = files.items.map((item) => deleteObject(item));

    return Promise.all(deletePromises);

  };

  const returnMyProjects = () => {
    window.scrollTo(0, 0)
    setShowProject(false)
    setAction("")
  }

  const actionTranslateProject = (option, language) => {
    setAction("")
    setOpenDialog(false)
    setMsgDialog("")
    setOptionsDialog([])
    if (option === "Cancelar") {
      return
    }
    setDestinyLanguage(language)
  }

  const handleProjectLanguage = (lang) => {
    switch (lang) {
      case "en-US":
        setProjectLanguage("English")
        break;
      case "es-ES":
        setProjectLanguage("Spanish")
        break;
      case "pt-BR":
        setProjectLanguage("Portuguese")
        break;
      case "it-IT":
        setProjectLanguage("Italian")
        break;
      case "fr-FR":
        setProjectLanguage("French")
        break;
      case "de-DE":
        setProjectLanguage("German")
        break;
      case "ru-RU":
        setProjectLanguage("Russian")
        break;
      case "ja-JP":
        setProjectLanguage("Japanese")
        break;
      case "ko-KR":
        setProjectLanguage("Korean")
        break;
      case "zh-CN":
        setProjectLanguage("Mandarin")
        break;
    }
  }

  const handleTextsToTranslate = async (language) => {
    setTranslatingProject(true);

    if (projectName) {
      projectTranslate.projectName = projectName;
    }
    if (projectAssessment) {
      projectTranslate.projectAssessment = projectAssessment;
    }
    if (projectSubtitles) {
      projectTranslate.projectSubtitles = projectSubtitles;
    }
    if (projectVideoText) {
      projectTranslate.projectVideoText = projectVideoText;
    }
    if (projectPptText) {
      projectTranslate.projectPptText = projectPptText;
    }
    if (projectMenu) {
      projectTranslate.projectMenu = projectMenu;
    }
    if (projectContentGenerated) {
      projectTranslate.projectContentGenerated = projectContentGenerated;
    }
    if (projectInteraction) {
      projectTranslate.projectInteraction = projectInteraction
    }

    try {
      const res = await handleOpenAiApi({
        objectSubtitle: projectTranslate,
        language: destinyLanguage,
        orderGPT: "translate",
      });

    } catch (error) {
      setAction("")
      setOpenDialog(false)
      setMsgDialog("")
      setTranslatingProject(false);
    }
  }

  const updateProjectTranslate = (original, translated) => {
    function updateStrings(originalObj, translatedObj) {
      for (const key in originalObj) {
        if (Array.isArray(originalObj[key])) {
          originalObj[key].forEach((item, index) => {
            updateStrings(item, translatedObj[key][index]);
          });
        } else if (typeof originalObj[key] === 'object' && originalObj[key] !== null) {
          updateStrings(originalObj[key], translatedObj[key]);
        } else if (typeof originalObj[key] === 'string') {
          originalObj[key] = translatedObj[key];
        }
      }
    }

    const updatedProject = JSON.parse(JSON.stringify(original));
    updateStrings(updatedProject, translated);
    return updatedProject;
  }

  const createAndUpadateProject = async () => {
    const newIdProject = projectName + Timestamp.now()
    await duplicateDocument("Domains", userId, "Folder", idProject, newIdProject, projectName)

    const clonedProjects = projectClonedIds.length === 0 ? [idProject, newIdProject] : [...projectClonedIds, newIdProject];
    const updateCloned = {
      id: projectIdCloned != "" ? projectIdCloned : idProject,
      domain: userId,
      cloned_projects: clonedProjects
    }
    await updateNewFBProject(updateCloned)

    const newProject = {
      id: newIdProject,
      menu_items: projectMenu || [],
      assessment: projectAssessment || [],
      content_generated: projectContentGenerated || "",
      subtitle: projectSubtitles || [],
      project_language: projectLanguage,
      subtitle_language: destinyLanguage || "",
      ppt_text: projectPptText || "",
      video_text: projectVideoText || "",
      creation_date: Timestamp.now(),
      project_size: 0.1,
      domain: userId,
      cloned_id: projectIdCloned ? projectIdCloned : idProject,
      video_interaction: projectInteraction || []
    }

    await updateNewFBProject(newProject)
    setTranslatingProject(false);
    setAction("")
    setOpenDialog(false)
    setMsgDialog("")
    setDuplicateProject(false)
    window.scrollTo(0, 0);
  }

  const duplicateDocument = async (originalCollection, userDocId, projectsCollection, originalDocId, newDocId, newProjectName) => {

    try {
      const originalDocRef = doc(db, originalCollection, userDocId, projectsCollection, originalDocId);
      const originalDocSnapshot = await getDoc(originalDocRef);

      if (originalDocSnapshot.exists()) {
        const docData = originalDocSnapshot.data();
        docData.project_name = newProjectName;
        docData.id = newDocId;
        const newDocRef = doc(db, originalCollection, userDocId, projectsCollection, newDocId);

        await setDoc(newDocRef, docData);

      } else {
        throw new Error('Documento original não encontrado.');
      }
    } catch (err) {
      setErroruplicatingDoc(err.message);
      console.error('Erro ao duplicar o documento:', err);
    }
  };

  const updateNewFBProject = async (newDocument) => {
    const res = updateDocument(newDocument);
  }

  useEffect(() => {
    if (translatingProject) {
      setAction("askingIA")
      setOpenDialog(true)
      setMsgDialog(t('myprojects:messages.traduzindo projeto'))
    }
  }, [translatingProject])

  useEffect(() => {
    if (objectTanslated) {
      const testetraducao = updateProjectTranslate(projectTranslate, objectTanslated)
    }
  }, [objectTanslated])

  useEffect(() => {
    if (returnGPT) {
      setProjectName(returnGPT.projectName)
      setProjectAssessment(returnGPT.projectAssessment)
      setProjectSubtitles(returnGPT.projectSubtitles)
      setProjectVideoText(returnGPT.projectVideoText)
      setProjectPptText(returnGPT.projectPptText)
      setProjectMenu(returnGPT.projectMenu)
      setProjectContentGenerated(returnGPT.projectContentGenerated)
      setProjectInteraction(returnGPT.projectInteraction)
      setDuplicateProject(true)
    }
  }, [returnGPT])

  useEffect(() => {
    if (projectInteraction) {
      console.log("projectInteraction: " + projectInteraction)
    }
  }, [projectInteraction])

  useEffect(() => {
    if (errorGPT) {
      console.log("errorGPT: " + errorGPT)
      setAction("")
      setOpenDialog(false)
      setMsgDialog(null)
      setOptionsDialog([])
      setTranslatingProject(false);
      setErrorTranslating(errorGPT)
    }
  }, [errorGPT])


  useEffect(() => {
    if (errorTranslating) {
      setAction("close_dialog_box")
      setOpenDialog(true)
      setMsgDialog(t('myprojects:messages.Erro ao traduzir projeto'))
      setOptionsDialog(['Ok'])
    }
  }, [errorTranslating])

  useEffect(() => {
    if (errorDuplicatingDoc) {
      setAction("close_dialog_box")
      setOpenDialog(true)
      setMsgDialog(t('myprojects:messages.Erro ao duplicar o projeto'))
      setOptionsDialog(['Ok'])
    }
  }, [errorDuplicatingDoc])

  //muito cuidado com esse useEffect
  useEffect(() => {
    if (duplicateProject) {
      createAndUpadateProject()
    }
  }, [duplicateProject])

  useEffect(() => {
    if (destinyLanguage) {
      handleProjectLanguage(destinyLanguage)
      handleTextsToTranslate(destinyLanguage)
    }
  }, [destinyLanguage])

  useEffect(() => {
    if (domain) {
      setStoragePPtSize(domain.ppt_storage_size)
      setStorageVideoSize(domain.video_storage_size)
    }
  }, [domain])

  useEffect(() => {
    if (updateSize) {
      const userInfos = {
        domain: userId,
        ppt_storage_size: storagePPtSize,
        video_storage_size: storageVideoSize,
      };
      setDeletingProject(false)
      const resDoc = updateDocument(userInfos);
      setUpdateSize(false)
    }
  }, [updateSize])

  useEffect(() => {
    const actionMenu = () => {
      action != "close_rename_folder" && action != "" && setCreateFolder(false);
      setCreateProject(false);
      setUpdateProject(false);
      switch (action) {
        case "delete":
          setOptionsDialog([t('myprojects:buttons.Cancelar'), t('myprojects:buttons.Apagar')])
          folderChange
            ? setMsgDialog(t('myprojects:messages.deseja apagar pasta', {
              folderChange: folderChange,
              interpolation: { prefix: '${', suffix: '}' }
            }))
            : setMsgDialog(t('myprojects:messages.deseja apagar projeto', {
              projectName: projectName,
              interpolation: { prefix: '${', suffix: '}' }
            }))
          return setOpenDialogDelete(true);
        case "rename":
          setOptionsDialog([t('myprojects:buttons.Cancelar'), t('myprojects:buttons.Renomear')])
          folderChange
            ? setMsgDialog(t('myprojects:messages.Renomear folder para', {
              folderChange: folderChange,
              interpolation: { prefix: '${', suffix: '}' }
            }))
            : setMsgDialog(t('myprojects:messages.Renomear projectName para', {
              projectName: projectName,
              interpolation: { prefix: '${', suffix: '}' }
            }))
          return setOpenDialogRename(true);
        case "edit":
          return actionEditProject()
        case "move":
          setMsgDialog(t('myprojects:messages.qual pasta mover o projeto', {
            projectName: projectName,
            interpolation: { prefix: '${', suffix: '}' }
          }))
          setOptionsDialog([t('myprojects:buttons.Cancelar'), t('myprojects:buttons.Mover')])
          setFoldersMove(folders)
          return setOpenDialogFolder(true);
        case "close_rename_folder":
          setMsgDialog(t('myprojects:messages.pasta já existe', {
            folderName: folderName,
            interpolation: { prefix: '${', suffix: '}' }
          }));
          setOptionsDialog(["Ok"])
          return setOpenDialog(true);
        case "translate_project":
          return actionTranslate();
        default: return null
      }
    }
    actionMenu();
  }, [action, idProject])

  useEffect(() => {
    projects && projects.map((project) => {
      project.id === "folder" && setFolders(project.folder_name);
    })
    if (projects && projects.length > 0) {
      setShowCarousel(false);
      setHelloMessage(t('myprojects:messages.Confira abaixo suas pastas e projetos'))
    } else {
      setShowCarousel(true);
      setHelloMessage(t('myprojects:messages.Você ainda não tem projetos e pastas criados'))
    }
  }, [projects])

  useEffect(() => {
    if (projects) {
      visibleProjects = projects.filter(project => project.id !== "folder" && project.folder === actualFolder && project.project_active);
      setProjectCount(visibleProjects.length)
      lastVisibleProjectId = visibleProjects.length > 0 ? visibleProjects[visibleProjects.length - 1].id : null;
    }
  }, [projects])

  useEffect(() => {
    user && setUserName(user.displayName);
  }, [user]);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [createProject]);

  useEffect(() => {
    if (userDomainPlan.includes("trial")) {
      setFormattedPlan("Trial");
    } else if (userDomainPlan === 'pro-monthly') {
      setFormattedPlan(t('myprojects:messages.Pro (mensal)'));
    } else if (userDomainPlan === 'pro-annual') {
      setFormattedPlan(t('myprojects:messages.Pro (anual)'));
    } else {
      setFormattedPlan("Basic (free)");;
    }
  }, [userDomainPlan])

  useEffect(() => {
    if (preferenceLanguage) {
      setItemsDrop([
        t('myprojects:buttons.Editar'),
        t('myprojects:buttons.Renomear'),
        t('myprojects:buttons.Mover'),
        t('myprojects:buttons.Apagar'),
      ]);
    }
  }, [preferenceLanguage])

  useEffect(() => {
    projects && setLoading(false);
  }, [projects])

  useEffect(() => {
    if (createFolder) {
      inputRef.current.focus();
    }
  }, [createFolder]);

  useEffect(() => {
    userDoc && setFirstAccess(userDoc.first_access)
  }, [userDoc])

  useEffect(() => {
    if (projects && folders) {
      totalObjects = shownProjectsCount + shownFoldersCount
      isPair = totalObjects % 2 === 0;
    }
  }, [projects, folders])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    hideMenuItem("myprojects")
  }, [])

  useEffect(() => {
    if (dataFetch) {
      const fetchLimits = async () => {
        const [limitAvailable, limitPptAvailable, limitVideoAvailable] = checkDomainProjectsLimit();
        const [limitStorage, avaliableStorage, dataStorageUsedPlan] = await checkStorageLimit();
        setLimitAvaliable(limitAvailable);
        const status = checkSubscriptionStatus();
        setDomainStatus(status);
      };

      fetchLimits();
    }
  }, [dataFetch])

  useEffect(() => {
    if (domainStatus && domainStatus != "active") {
      setDomainActive(false)
    }
  }, [domainStatus])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt";
          localeDate = pt;
          break;
        case "Espanhol":
          language = "es";
          localeDate = es;
          break;
        case "Inglês":
          language = "en";
          localeDate = enUS;
          break;
        default:
          language = "en";
          localeDate = enUS;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  useEffect(() => {
    if (deletingProject) {
      setAction("askingIA")
      setOpenDialog(true)
      setMsgDialog(t('myprojects:messages.Apagando projeto'))
    } else {
      setAction("")
      setOpenDialog(false)
      setMsgDialog("")
    }
  }, [deletingProject])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <div className={`${styles.main_background} m-0 p-0`}>
      {loading
        ? <div class="d-flex justify-content-center mt-5">
          <div class="align-self-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </div>
        : <div>
          <div className="col-12">
            {updateAssessment && (
              <div
                key={key}
                className={`${!showProject && styles.div_project_settings} `}
              >
                <UpdateAssessment
                  projectInfos={projectInfos}
                  hideCardFolderProject={hideUpdateAssessment} />
              </div>
            )}
          </div>

          {/*<div>
            {showCarousel && firstAccess && <StartCarousel handleCloseCarousel={handleCloseCarousel} userId={user.uid} />}
          </div>*/}

          {!showProject &&
            <div>
              {user &&
                <div className="mt-3 ms-2  me-2 mb-3 " >
                  {projects && projectCount || folder ?
                    <div>
                      <p></p>
                      {/* Show folders if source folder */}
                      <div className={`row ${styles.icons_row}`}>
                        {actualFolder === "Folder" &&
                          <div className='col-auto'>
                            <i className={`bi bi-folder-plus ${styles.folder_plus}`} onClick={() => setCreateFolder(true)} onMouseEnter={() => setShowHint1(true)} onMouseLeave={() => setShowHint1(false)}></i>
                            {showHint1 && <h5 className={`${styles.icon_texts}`}>{t('myprojects:home.adicionar pasta')}</h5>}
                          </div>
                        }

                        <div className='col-auto'>
                          <NavLink to="/" >
                            <i className={`bi bi-file-earmark-plus ${styles.folder_plus}`} onMouseEnter={() => setShowHint2(true)} onMouseLeave={() => setShowHint2(false)}></i>
                          </NavLink>
                          {showHint2 && <h5 className={`${styles.icon_texts}`}>{t('myprojects:home.novo projeto')}</h5>}
                        </div>
                      </div>

                      {/* Show user projects*/}
                      <div className={`row d-flex align-items-center justify-content-center ${styles.MyProjects}`}>
                        {createFolder && (
                          <div className={`col-12 col-lg-5 d-flex align-items-center justify-content-center ${styles.projects}`} onMouseLeave={() => setVisibleMenuFolderId("")}>
                            <div className="row">
                              <div className={`col-4 col-md-3 d-flex align-items-center justify-content-center ${styles.MyProjects_folder_icon}`}>
                                <img className="img-fluid" src={foldericon} alt="folder" />
                              </div>
                              <div className={`col-7 d-flex align-items-center text-left ${styles.MyProjects_folders_name}`} >
                                <div className={`ps-2 `}>
                                  <input
                                    className={`col-12 ${styles.type_folder}`}
                                    type="text"
                                    required
                                    name="folderName"
                                    placeholder={t('myprojects:home.Digite o nome da pasta')}
                                    value={folderName}
                                    onChange={(e) => { setFolderName(e.target.value) }}
                                    ref={inputRef}
                                  />
                                </div>
                              </div>
                              <div className={`col-1 d-flex justify-content-center`}>
                                <button className={`${styles.closeFolder}`}><i className={`bi bi-x-lg`} onClick={hideCardFolderProject} ></i></button>
                                <button className={`${styles.createFolder}`} disabled={folderName === ""}> <i className={`bi bi-check-lg ps-3 `} onClick={handleCreateFolder} ></i></button>
                              </div>
                            </div>
                          </div>
                        )}
                        {actualFolder === "Folder" && folders && folders.map((folder) => {
                          shownFoldersCount++
                          return (<div className={`col-12 col-lg-5 d-flex align-items-center justify-content-center ${styles.projects}`} onMouseLeave={() => setVisibleMenuFolderId("")}>

                            <div className={`${styles.btnMenu}`}>
                              <a onClick={() => setVisibleMenuFolderId(visibleMenuFolderId === folder ? null : folder)}><img className="img-fluid p-1" src={menuV} alt="menuV" /></a>
                            </div>
                            <div className="row">
                              <div className={`col-4 col-md-3 d-flex align-items-center justify-content-center ${styles.MyProjects_folder_icon}`}>
                                <img className="img-fluid" src={foldericon} alt="folder" />
                              </div>
                              <div className={`col-8 d-flex align-items-center text-left ${styles.MyProjects_folders_name}`} onClick={() => handleChangeFolder(folder)}>
                                <h3>{folder}</h3>
                              </div>
                            </div>
                            <div className="row">
                              <div className={`col-12 pt-3 text-end ${styles.esconde} ${visibleMenuFolderId === folder ? styles.mostrar : null}`}>
                                <ul>
                                  <li><a onClick={() => handleChangeDropdown("rename", null, folder)}>{t('myprojects:buttons.Renomear')}</a></li>
                                  <li><a onClick={() => handleChangeDropdown("delete", null, folder)}>{t('myprojects:buttons.Apagar')}</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>)
                        })}
                        {/* Show folder icon to return to source folder if user folder*/}
                        {actualFolder != "Folder" &&
                          <div className={`col-12 col-lg-5 d-flex align-items-center justify-content-center ${styles.btnBackFolder}`}>
                            <div className="row" onClick={() => handleChangeFolder("Folder")}>
                              <div className={`col-4 col-md-3 d-flex align-items-center justify-content-center ${styles.MyProjects_folder_icon}`}>
                                <img className="img-fluid" src={foldericon} alt="foldericon" />
                              </div>
                              <div className={`col-8 d-flex align-items-center text-left ${styles.MyProjects_folders_name}`}>
                                <h2>{t('myprojects:home.VOLTAR')}</h2>
                              </div>
                            </div>
                          </div>
                        }
                        {projects.map((project, index) => {
                          if (project.id === "folder" || project.project_active) {
                            var projectHasTexts = false;

                            const projectAssessment = (project.assessment && project.assessment.length) > 0 ? true : false;
                            const projectSubtitles = (project.subtitle && project.subtitle.length) > 0 ? true : false;
                            const projectIncludeContent = project.include_content;
                            const projectIncludeCase = project.include_case;
                            const projectContentGenerated = project.content_generated;

                            const creationDateTimestamp = project.creation_date;
                            const creationDate = new Date(creationDateTimestamp.toDate());
                            const formattedCreationDate = format(
                              creationDate,
                              preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
                              {
                                locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
                              }
                            );
                            const id = project.id;
                            const projectname = project.project_name;
                            const projectsize = project.project_size || 0;
                            var formattedProjectSize = projectsize.toFixed(2);
                            if (formattedProjectSize == 0) {
                              formattedProjectSize = 0.01;
                            }
                            const projectLanguage = checkProjectLanguage(project.project_language);
                            const showProject = project.folder === actualFolder;

                            if (project.id !== "folder") {
                              if (showProject) {
                                shownProjectsCount++;

                                return (
                                  <div className={`col-12 col-lg-5 align-items-center justify-content-center d-flex mt-3 ${domainActive ? styles.projects : styles.projects_disabled}`} key={project.id} onMouseLeave={() => setVisibleMenuProjectId("")}>
                                    <div className={`${styles.btnMenu}`}>
                                      <a onClick={() => setVisibleMenuProjectId(visibleMenuProjectId === project.id ? null : project.id)}>
                                        <img className="img-fluid p-1" src={menuV} alt="menuV" />
                                      </a>
                                    </div>
                                    <div className="row">
                                      <div className={`col-4 col-md-3 d-flex align-items-center justify-content-center ${styles.MyProjects_folder_icon}`}>
                                        <img className="img-fluid" src={
                                          project.include_slides ? ppt :
                                            project.include_video ? videoicon :
                                              project.include_content ? texticon :
                                                project.include_case ? cases :
                                                  assessment
                                        } />
                                      </div>
                                      <div className={`col-9 col-md-9 d-flex align-items-center text-left ${styles.MyProjects_project_name}`} onClick={domainActive ? () => handleChangeDropdown("edit", project, folderName) : null}>
                                        <ul>
                                          <li>
                                            <h2>{projectname}</h2>
                                          </li>
                                          <li className="d-none d-sm-block">
                                            <time>{formattedCreationDate}</time>
                                            <language> | {projectLanguage}</language>
                                          </li>
                                          <li>
                                            <h5>{formattedProjectSize} Mb</h5>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className={`col-12 text-end d-flex ${styles.esconde} ${visibleMenuProjectId === project.id ? styles.mostrar : null}`}>
                                        <ul>
                                          <li><a onClick={() => handleChangeDropdown("edit", project, null)}>{t('myprojects:buttons.Editar')}</a></li>
                                          <li><a onClick={() => handleChangeDropdown("rename", project, null)}>{t('myprojects:buttons.Renomear')}</a></li>
                                          <li><a onClick={() => handleChangeDropdown("move", project, null)}>{t('myprojects:buttons.Mover')}</a></li>
                                          <li><a onClick={() => handleChangeDropdown("delete", project, null)}>{t('myprojects:buttons.Apagar')}</a></li>
                                          <li className={`${(projectAssessment || projectSubtitles || projectIncludeContent || projectIncludeCase) ? null : styles.disabled_link}`}><a onClick={() => handleChangeDropdown("translate_project", project, null)}>{t('myprojects:buttons.Traduzir')}</a></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            } else {
                              hasFolder = true;
                              counterFolder = project.counter;
                            }
                          }
                        })}
                        {/* Adiciona a última div vazia e transparente */}
                        <div className={`col-12 col-lg-5 align-items-center justify-content-center d-flex mt-3 ${styles.projects} ${styles.transparentDiv}`}></div>
                      </div>

                      {/* Show Dialog Delete*/}
                      <div>
                        {(openDialogDelete || openDialogRename || openDialogFolder || openDialog) &&
                          <DialogBox
                            action={action}
                            actionScript={action === "delete" ? actionDelete : action === "rename" ? actionRename : action === "move" ? actionMoveFolder : action === "close_rename_folder" || action === "close_dialog_box" ? actionCloseDialogBox : action === "translate_project" ? actionTranslateProject : actionEditProject}
                            questionDialog={msgDialog}
                            optionsDialog={optionsDialog}
                            actualFolder={actualFolder}
                            foldersMove={foldersMove}
                            isopen={true}
                          />}
                      </div>
                    </div>
                    : <div>
                      <h4>{t('myprojects:home.Você ainda não tem projetos criados')}</h4>
                    </div>
                  }
                </div>}
            </div>}
          {showProject &&
            <ProjectPreview
              id={idProject}
              projectSelected={projectSelected}
              returnHome={returnMyProjects}
              reviewProject={true}
              limitAvaliable={limitAvaliable}
            />}
          <p></p>
        </div>}
    </div>

  )
}

export default MyProjects;