import styles from './DeleteAccount.module.css'
import React from 'react'
import { useEffect, useState } from 'react';
import { useLanguageValue } from '../context/LanguageContext';
import { useAuthentication } from '../hooks/useAuthentications';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import DialogBoxDeleteAccount from './DialogBoxDeleteAccount';
import DialogBox from './DialogBox';

//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const DeleteAccount = ({ userId, isActive }) => {

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState();

  const { t } = useTranslation(['myaccount']);
  const { preferenceLanguage } = useLanguageValue();

  const auth = getAuth();

  const { logout } = useAuthentication();
  const { updateDocument, responseUpdate } = useUpdateDocument("Users", userId);
  const { document: userInfo, loadingUser, errorUser } = useFetchDocument("Users", userId);

  const handleOpenDialogBox = () => {
    setMessage(t(`myaccount:deleteaccount.Para excluir sua conta`));
    setOpenDialogDelete(true)
  }

  const actionDelete = (confirmed, email, password) => {
    if (confirmed) {
      handleDeleteAccount(email, password);
    }
    setOpenDialogDelete(false);
  }

  const handleClosDialogBox = () => {
    if ("Ok") {
      logout();
    }
    setOpenDialog(false);
  }

  const handleDeleteAccount = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await auth.currentUser.delete();

    } catch (error) {
      console.error('Erro ao apagar conta:', error);
    }
  }

  useEffect(() => {
    if (preferenceLanguage) {
        var language = "en";
        switch (preferenceLanguage) {
            case "Português":
                language = "pt";
                break;
            case "Espanhol":
                language = "es";
                break;
            case "Inglês":
                language = "en";
                break;
            default:
                language = "en";
        }
        changeLanguage(language);
        i18n.changeLanguage(language);
    }
}, [preferenceLanguage])

const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
};


  return (
    <div className="pt-4 ps-3 pe-5">
      <div className="row" >
        <div className="col-auto">
          <i className="bi bi-exclamation-triangle-fill fs-4"></i>
        </div>
        <div className="col-11">
          <h6 ><strong >{t(`myaccount:deleteaccount.Atenção`)} </strong> {t(`myaccount:deleteaccount.Todos os seus dados serão apagados`)}</h6>
        </div>
      </div>
      <div className="pt-4">
        <button className={`${styles.btn_style}`} disabled={!isActive} onClick={() => handleOpenDialogBox()}>{t(`myaccount:deleteaccount.Quero apagar a minha conta`)}</button>
      </div>
      {openDialogDelete && <DialogBoxDeleteAccount
        isopen={true}
        questionDialog={message}
        actionDelete={actionDelete} />}
      {openDialog && <DialogBox
        isopen={true}
        questionDialog={message}
        optionsDialog={["Ok"]}
        actionScript={handleClosDialogBox} 
        action={""}/>}
    </div>
  )
}

export default DeleteAccount