import styles from './Homepage.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Carousel } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
import { useAuthentication } from '../../hooks/useAuthentications';
//Dev Components
import DialogBox from '../../components/DialogBox';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//Images
import bgCarousel from '../../images/bg_banner.jpg';
import buttonGo from '../../images/bt_avancar.png';
import buttonBack from '../../images/bt_voltar.png';
import banner1en from '../../images/banner1.png';
import banner2en from '../../images/banner2.png';
import banner3en from '../../images/banner3.png';
import banner4en from '../../images/banner4.png';
import banner1pt from '../../images/banner1_ptBR.png';
import banner2pt from '../../images/banner2_ptBR.png';
import banner3pt from '../../images/banner3_ptBR.png';
import banner4pt from '../../images/banner4_ptBR.png';
import banner1sp from '../../images/banner1_ES.png';
import banner2sp from '../../images/banner2_ES.png';
import banner3sp from '../../images/banner3_ES.png';
import banner4sp from '../../images/banner4_ES.png';

const Homepage = ({ userSetLanguage }) => {
  const [message, setMessage] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [action, setAction] = useState("");
  const [banner1, setBanner1] = useState(banner1en);
  const [banner2, setBanner2] = useState(banner2en);
  const [banner3, setBanner3] = useState(banner3en);
  const [banner4, setBanner4] = useState(banner4en);
  const [imagesBunner, setImagesBunner] = useState([]);

  const { user } = useAuthValue();
  const { t } = useTranslation(['homepage']);
  const { preferenceLanguage } = useLanguageValue();
  const { login, error: authError, loading, loginWithGoogle } = useAuthentication(userSetLanguage);

  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");

  const navigate = useNavigate();

  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

  const loginGoogle = async () => {
    const res = await loginWithGoogle();
  }

  const actionCloseDialogBox = (f, option) => {
    setAction("");
    setOpenDialogBox(false);
  }

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setImagesBunner([banner1pt, banner2pt, banner3pt, banner4pt]);
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setImagesBunner([banner1en, banner2en, banner3en, banner4en]);
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setImagesBunner([banner1sp, banner2sp, banner3sp, banner4sp]);
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setImagesBunner([banner1en, banner2en, banner3en, banner4en]);
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (userSetLanguage) {
      if (userSetLanguage === "pt") {
        setImagesBunner([banner1pt, banner2pt, banner3pt, banner4pt]);
      } else if (userSetLanguage === "es") {
        setImagesBunner([banner1sp, banner2sp, banner3sp, banner4sp]);
      } else {
        setImagesBunner([banner1en, banner2en, banner3en, banner4en]);
      }
    }
  }, [userSetLanguage])

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) { 
        case "Português":
          setImagesBunner([banner1pt, banner2pt, banner3pt, banner4pt]);
          language = "pt";
          break;
        case "Espanhol":
          setImagesBunner([banner1sp, banner2sp, banner3sp, banner4sp]);
          language = "es";
          break;
        case "Inglês":
          setImagesBunner([banner1en, banner2en, banner3en, banner4en]);
          language = "en";
          break;
        default:
          setImagesBunner([banner1en, banner2en, banner3en, banner4en]);
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  useEffect(() => {
    console.log("imagesBunner", imagesBunner)
  }, [imagesBunner])

  useEffect(() => {
    if (authError) {
      setMessage(authError)
      setOpenDialogBox(true)
    }
  }, [authError])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const nextControl = document.querySelector('.carousel-control-next');
    const prevControl = document.querySelector('.carousel-control-prev');
    const indicators = document.querySelector('.carousel-indicators');
    if (nextControl && prevControl && indicators) {
      nextControl.classList.add(styles.carouselControls_next);
      prevControl.classList.add(styles.carouselControls_prev);
      indicators.classList.add(styles.carouselIndicators);
    }
  }, []);

  /* --- FIM --- forçar para que todas as divs .card tenham a mesma altura */

  return (
    <div className={`row ${styles.row_items}`} style={{ backgroundImage: `url(${bgCarousel})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

      <div className={`mt-5 mb-1`}>
        <Carousel
          className={styles.carousel}
          nextIcon={<img src={buttonGo} alt="Next" className={styles.carousel_next_icon} />}
          prevIcon={<img src={buttonBack} alt="Previous" className={styles.carousel_prev_icon} />}
          indicators={true}
          indicatorLabels={imagesBunner.map((_, index) => `Slide ${index + 1}`)}
          indicatorClassName={styles.indicators}
        >
          {imagesBunner.map((image, index) => (
            <Carousel.Item key={index} interval={5000}>
              <img
                className="d-block mx-auto w-50"
                src={image}
                alt={`Slide ${index + 1}`}
                onClick={() => navigate('/login')}
                style={{ cursor: 'pointer' }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="mt-5 mb-3 text-center">
        {!loading && <button onClick={() => loginGoogle()} className={`mt-3 ${styles.btn_google}`}><i className="bi bi-google me-3"></i> {t('homepage:home.login com google')}
        </button>}
      </div>
      {openDialogBox && <DialogBox
        action={action}
        actionScript={actionCloseDialogBox}
        isopen={true}
        questionDialog={message}
        optionsDialog={["Ok"]}
        userSetLanguage={userSetLanguage}
      />}
    </div>
  )
}

export default Homepage