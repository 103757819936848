//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card, Button } from 'react-bootstrap';
import styles from './AssessmentCase.module.css';
import defaultStyles from '../templates/default.module.css';
import aquaStyles from '../templates/aqua.module.css';
import blueStyles from '../templates/blue.module.css';
import cleanDarkStyles from '../templates/cleanDark.module.css';
import cleanLightStyles from '../templates/cleanLight.module.css';
import cleanNeutralStyles from '../templates/cleanNeutral.module.css';
import coralStyles from '../templates/coral.module.css';
import freshStyles from '../templates/fresh.module.css';
import greenStyles from '../templates/green.module.css';
import pastelStyles from '../templates/pastel.module.css';
import purpleRainStyles from '../templates/purpleRain.module.css';
import quizAIStyles from '../templates/quizai.module.css';

//React Hooks
import { useState, useEffect, useRef } from 'react';

const AssessmentCase = ({ contentGenerated, editProject, template, language, handleReviewContentGenerated, handleChangeContent, infoText, changeProjectName, indexCase }) => {

    const [caseTitle, setCaseTitle] = useState("");
    const [caseIntro, setCaseIntro] = useState("");
    const [companyDesc, setCompanyDesc] = useState("");
    const [problemContext, setProblemContext] = useState("");
    const [challengeCase, setChallengeCase] = useState("");
    const [problemAnalysis, setProblemAnalysis] = useState("");
    const [proposedSolution, setProposedSolution] = useState("");
    const [solutionImplemantation, setSolutionImplemantation] = useState("");
    const [resultsImpacts, setResultsImpacts] = useState("");
    const [lessonsLearned, setLessonsLearned] = useState("");
    const [caseConclusion, setCaseConclusion] = useState("");
    const [chooseTemplate, setChooseTemplate] = useState(template);
    const [contentCase, setContentCase] = useState(null)
    const [titleIntroduction, setTitleIntroduction] = useState("Introdução");
    const [titleDescription, setTitleDescription] = useState("Descrição");
    const [titleContext, setTitleContext] = useState("Contexto do Problema");
    const [titleChallenge, setTitleChallenge] = useState("Desafios");
    const [titleProblemAnalysis, setTitleProblemAnalysis] = useState("Análise do Problema");
    const [titleProposedSolution, setTitleProposedSolution] = useState("Solução Proposta");
    const [titleSolution, setTitleSolution] = useState("Implementação da Solução");
    const [titleResults, setTitleResults] = useState("Resultados e Impactos");
    const [titleLessons, setTitleLessons] = useState("Lições Aprendidas");
    const [titleConclusion, setTitleConclusion] = useState("Conclusão");
    const [contentReview, setContentReview] = useState();
    let wichLanguage = 0;
    let review;
    const words = [
        ["Título:", "Introdução", "Descrição", "Contexto", "Desafio/Problema", "Análise do Problema", "Solução Proposta", "Implementação da Solução", "Resultados e Impactos", "Lições Aprendidas", "Conclusão"],
        ["Title:", "Introduction", "Description", "Context", "Challenge/Problem", "Problem Analysis", "Proposed Solution", "Solution Implementation", "Results and Impacts", " Lessons Learned", "Conclusion"],
        ["Título:", "Introducción", "Descripción", "Contexto", "Desafío/Problema", "Análisis del Problema", "Solución Propuesta", "Implementación de la Solución", "Resultados e Impactos", "Lecciones Aprendidas", "Conclusión"],
        ["Titel:", "Einleitung", "Beschreibung", "Kontext", "Herausforderung/Problem", "Problemanalyse", "Vorgeschlagene Lösung", " Umsetzung der Lösung", " Ergebnisse und Auswirkungen", "Erfahrungswerte", "Schlussfolgerung"],
        ["Titre:", "Introduction", "Description", "Contexte", "Défi/Problème", "Analyse du Problème", "Solution Proposée", "Mise en œuvre de la Solution", "Résultats et Impacts", "Leçons Apprises", "Conclusion"],
        ["Titolo:", "Introduzione", "Descrizione", "Contesto", "Sfida/Problema", "Analisi del Problema", "Soluzione Proposta", "Implementazione della Soluzione", "Risultati e Impatti", "Lezioni Apprese", "Conclusione"],
        ["Заголовок:", "Введение", "Описание", "Контекст", "Вызов/Проблема", "Анализ Проблемы", "Предлагаемое Решение", "Внедрение Решения", "Результаты и Влияние", "Извлеченные Уроки", "Заключение"],
        ["标题:", "介绍:", "描述", "上下文", "挑战/问题", "问题分析", "提议的解决方案", "解决方案的实施", "结果和影响", "启示与教训", "结论"],
        ["タイトル:", "紹介", "説明", "コンテキスト", "チャレンジ/問題", "問題分析", "提案された解決策", "解決策の実施", "結果と影響", "得られた教訓", "結論"],
        ["제목:", "소개", "설명", "문맥", "도전/문제", "문제 분석", "제안된 해결책", "해결책의 구현", "결과 및 영향", "배운 교훈", "결론"]
    ];

    const saveReviewedContent = () => {
        handleChangeContent(true)
        review = formatarObjetoString(contentCase)
        setContentReview(review)
        const jsonContentReviwed = contentCase
        handleReviewContentGenerated(review, jsonContentReviwed)
    }

    const saveReviewedVideoCase = () => {
        handleChangeContent(indexCase, contentCase)
    }

    const formatarObjetoString = (objeto) => {

        let objetoString = ``;

        if(objeto.title){
            objetoString += `${words[wichLanguage][0]} ${objeto.title}\n`;
        }
        if(objeto.intro){
            objetoString += `${words[wichLanguage][1]} ${objeto.intro}\n`;
        } 
        if (objeto.company_description) {
            objetoString += `${words[wichLanguage][2]}: `;
            objetoString += `${objeto.company_description}\n`;
        }
        if(objeto.problem_context) {
            objetoString += `${words[wichLanguage][3]}: `;
            objetoString += `${objeto.problem_context}\n`;
        }
        if(objeto.problem_or_challenge_description){
            objetoString += `${words[wichLanguage][4]}: `;
            objetoString += `${objeto.problem_or_challenge_description}\n`;
        }
        if(objeto.problem_analysis){
            objetoString += `${words[wichLanguage][5]}: `;
            objetoString += `${objeto.problem_analysis}\n`;
        }
        if(objeto.proposed_solution){
            objetoString += `${words[wichLanguage][6]}: `;
            objetoString += `${objeto.proposed_solution}\n`;
        }
        if(objeto.solution_implementation){
            objetoString += `${words[wichLanguage][7]}: `;
            objetoString += `${objeto.solution_implementation}\n`;
        }
        if(objeto.results_and_impacts){
            objetoString += `${words[wichLanguage][8]}: `;
            objetoString += `${objeto.results_and_impacts}\n`;
        }
        if(objeto.lessons_learned){
            objetoString += `${words[wichLanguage][9]}: `;
            objetoString += `${objeto.lessons_learned}\n`;
        }
        if(objeto.conclusion){
            objetoString += `${words[wichLanguage][10]}: `;
            objetoString += `${objeto.conclusion}`;
        }

        return objetoString;
    };

    useEffect(() => {
        switch (language) {
            case "Portuguese":
                wichLanguage = 0;
                break;
            case "English":
                wichLanguage = 1;
                break;
            case "Spanish":
                wichLanguage = 2;
                break;
            case "German":
                wichLanguage = 3;
                break;
            case "French":
                wichLanguage = 4;
                break;
            case "Italian":
                wichLanguage = 5;
                break;
            case "Russian":
                wichLanguage = 6;
                break;
            case "Mandarin":
                wichLanguage = 7;
                break;
            case "Japanese":
                wichLanguage = 8;
                break;
            case "Korean":
                wichLanguage = 9;
                break;
            default:
                wichLanguage = 0;
                break;
        }
    }, [language])

    useEffect(() => {
        setCaseTitle(contentGenerated.title)
        setCaseIntro(contentGenerated.intro)
        setCompanyDesc(contentGenerated.company_description)
        setProblemContext(contentGenerated.problem_context)
        setChallengeCase(contentGenerated.problem_or_challenge_description)
        setProblemAnalysis(contentGenerated.problem_analysis)
        setProposedSolution(contentGenerated.proposed_solution)
        setSolutionImplemantation(contentGenerated.solution_implementation)
        setResultsImpacts(contentGenerated.results_and_impacts)
        setLessonsLearned(contentGenerated.lessons_learned)
        setCaseConclusion(contentGenerated.conclusion)
        setChooseTemplate(template)
    }, [contentGenerated])

    useEffect(() => {
        if (caseTitle, caseIntro, companyDesc, problemContext, challengeCase, problemAnalysis, proposedSolution, solutionImplemantation, resultsImpacts, lessonsLearned, caseConclusion) {
            const rev = {
                title: caseTitle ? caseTitle : "",
                intro: caseIntro ? caseIntro : "",
                company_description: companyDesc ? companyDesc : "",
                problem_context: problemContext ? problemContext : "",
                problem_or_challenge_description: challengeCase ? challengeCase : "",
                problem_analysis: problemAnalysis ? problemAnalysis : "",
                proposed_solution: proposedSolution ? proposedSolution : "",
                solution_implementation: solutionImplemantation ? solutionImplemantation : "",
                results_and_impacts: resultsImpacts ? resultsImpacts : "",
                lessons_learned: lessonsLearned ? lessonsLearned : "",
                conclusion: caseConclusion ? caseConclusion : ""
            }
            setContentCase(rev)
        }
    }, [caseTitle, caseIntro, companyDesc, problemContext, challengeCase, problemAnalysis, proposedSolution, solutionImplemantation, resultsImpacts, lessonsLearned, caseConclusion])

    useEffect(() => {
        if (contentCase && contentCase != contentGenerated) {
            if (indexCase) {
                saveReviewedVideoCase();
            } else {
                saveReviewedContent();
            }
        }
    }, [contentCase])

    useEffect(() => {
        if (template) {
            setChooseTemplate(template);
        }
    }, [template])

    useEffect(() => {
        caseTitle && changeProjectName(caseTitle)
    }, [caseTitle])

    useEffect(() => {
        if (wichLanguage) {
            setTitleIntroduction(words[wichLanguage][1])
            setTitleDescription(words[wichLanguage][2])
            setTitleContext(words[wichLanguage][3])
            setTitleChallenge(words[wichLanguage][4])
            setTitleProblemAnalysis(words[wichLanguage][5])
            setTitleProposedSolution(words[wichLanguage][6])
            setTitleSolution(words[wichLanguage][7])
            setTitleResults(words[wichLanguage][8])
            setTitleLessons(words[wichLanguage][9])
            setTitleConclusion(words[wichLanguage][10])
        }
    }, [wichLanguage])

    return (
        <div>
            <Accordion defaultActiveKey={null} >
                {caseIntro &&
                    <Accordion.Item eventKey="0" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`} >
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleIntroduction}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="intro"
                                    placeholder="Introdução"
                                    rows={3}
                                    value={caseIntro}
                                    onChange={(e) => setCaseIntro(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {companyDesc &&
                    <Accordion.Item eventKey="1" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleDescription}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5>
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="companyDesc"
                                    placeholder="Descrição"
                                    rows={3}
                                    value={companyDesc}
                                    onChange={(e) => setCompanyDesc(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {problemContext &&
                    <Accordion.Item eventKey="2" className={chooseTemplate.acordionItem}>
                        <Accordion.Header className={chooseTemplate.accordion_header}>
                            <h5 className={chooseTemplate.title_videoCase}>{titleContext}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={chooseTemplate.acordionBody} style={{ padding: '0px', margin: '10px' }}>
                            <h5>
                                <textarea
                                    disabled={!editProject}
                                    className={chooseTemplate.videoCase}
                                    type="text"
                                    name="problemContext"
                                    placeholder="Contexto do Problema"
                                    rows={3}
                                    value={problemContext}
                                    onChange={(e) => setProblemContext(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {challengeCase &&
                    <Accordion.Item eventKey="3" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleChallenge}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="challengecase"
                                    placeholder="Desafios"
                                    rows={3}
                                    value={challengeCase}
                                    onChange={(e) => setChallengeCase(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {problemAnalysis &&
                    <Accordion.Item eventKey="4" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleProblemAnalysis}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="problemanalysis"
                                    placeholder="Análise do Problema"
                                    rows={3}
                                    value={problemAnalysis}
                                    onChange={(e) => setProblemAnalysis(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {proposedSolution &&
                    <Accordion.Item eventKey="5" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleProposedSolution}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="proposedSolution"
                                    placeholder="Solução Proposta"
                                    rows={3}
                                    value={proposedSolution}
                                    onChange={(e) => setProposedSolution(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {resultsImpacts &&
                    <Accordion.Item eventKey="7" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleResults}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="resultsImpacts"
                                    placeholder="Resultados e Impactos"
                                    rows={3}
                                    value={resultsImpacts}
                                    onChange={(e) => setResultsImpacts(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {lessonsLearned &&
                    <Accordion.Item eventKey="8" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`} >
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleLessons}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="lessonsLearned"
                                    placeholder="Lições Aprendidas"
                                    rows={3}
                                    value={lessonsLearned}
                                    onChange={(e) => setLessonsLearned(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}

                {caseConclusion &&
                    <Accordion.Item eventKey="9" className={`${chooseTemplate.acordionItem}`}>
                        <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                            <h5 className={`${chooseTemplate.title_videoCase}`}>{titleConclusion}</h5>
                        </Accordion.Header>
                        <Accordion.Body className={`${chooseTemplate.acordionBody} ${chooseTemplate.conclusionBox}`} style={{ padding: '0px', margin: '10px' }}>
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.videoCase}`}
                                    type="text"
                                    name="caseConclusion"
                                    placeholder="Conclusão"
                                    rows={3}
                                    value={caseConclusion}
                                    onChange={(e) => setCaseConclusion(e.target.value)}
                                />
                            </h5>
                        </Accordion.Body>
                    </Accordion.Item>}
            </Accordion>
        </div>
    )
}

export default AssessmentCase;
