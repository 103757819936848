import styles from './Preferences.module.css'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useLanguageValue } from '../context/LanguageContext';

import DialogBox from './DialogBox';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Preferences = ({ userId, isActive }) => {

    const [language, setLanguage] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [error, setError] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation(['myaccount']);
    const { preferenceLanguage } = useLanguageValue();

    const [message, setMessage] = useState(t('myaccount:preferences.Idioma preferência atualizado'));
    const [userLanguage, setUserLanguage] = useState();

    const { updateDocument, responseUpdate } = useUpdateDocument("Users", userId);
    const { document: userInfo, loadingUser, errorUser } = useFetchDocument("Users", userId);

    const handleClosDialogBox = () => {
        setOpenDialog(false);
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        setError("");

        if (!language) {
            setError(t('myaccount:preferences.Você deve indicar idioma preferência'));
            return
        }

        const userInfos = {
            domain: userId,
            language: language,
        }
        const resDoc = updateDocument(userInfos);

        setOpenDialog(true);

    }

    useEffect(() => {
        userInfo && setLanguage(userInfo.language)
        userInfo && setLoading(loadingUser);
    }, [userInfo])

    useEffect(() => {
        if (userLanguage) {
            setMessage(t('myaccount:preferences.Idioma de preferência atualizado'));
        }
    }, [userLanguage])

    useEffect(() => {
        preferenceLanguage && setUserLanguage(preferenceLanguage)
    }, [preferenceLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            {loading
                ? <div class="d-flex justify-content-center mt-5">
                    <div class="align-self-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{t('myaccount:preferences.Carregando')}</span>
                        </div>
                    </div>
                </div>
                : <div>
                    <div className={`row mt-3 d-flex align-items-center`}>
                        <h6 className={`col-auto`}>{t('myaccount:preferences.Idioma do sistema')}</h6>
                        <div onMouseOver={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} className="col-auto">
                            <a >
                                <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                            </a>
                        </div>
                        {showInfo && (
                            <div
                                className={`col-4 ${styles.info_boxes_preferences}`}
                            >
                                <div
                                    className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                    role="alert"
                                >
                                    <strong>{`Importante!`}</strong> {t('myaccount:preferences.Escolha idioma interface')}
                                </div>
                            </div>
                        )}
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-4 mt-3">
                                        <label>
                                            <select
                                                className={`${styles.form_field}`}
                                                required
                                                name="language"
                                                value={language}
                                                onChange={(e) => setLanguage(e.target.value)}>
                                                <option disabled selected>{t('myaccount:preferences.Selecione um idioma')}</option>
                                                <option value="Português">{t('myaccount:preferences.Português')}</option>
                                                <option value="Inglês">{t('myaccount:preferences.Inglês')}</option>
                                                <option value="Espanhol">{t('myaccount:preferences.Espanhol')}</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="row d-flex justify-content-end mt-4">
                                        <button className={`${styles.btn_style}`} disabled={!isActive}>{t('myaccount:preferences.Atualizar')}</button>
                                    </div>
                                    {error && <p className="error">{error}</p>}
                                </div>
                            </form>
                        </div>
                    </div>

                    {openDialog && <DialogBox
                        isopen={true}
                        questionDialog={message}
                        optionsDialog={["Ok"]}
                        actionScript={handleClosDialogBox}
                        action={""}
                    />}
                </div>
            }
        </div>
    )
}

export default Preferences