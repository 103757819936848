//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './VideoInteraction.module.css'
//React Hooks
import { useEffect, useState } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
import { useUpdateDocument } from '../hooks/useUpdateDocument';

const VideoInteraction = ({ originalLanguage, contentUntilInteraction, pointInteraction, userId, projectId, closeVideoInteraction, projectVideoInteraction }) => {

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();
    const { handleOpenAiApi, returnGPT, textTranslated, loading, errorGPT, generateGPT } = useAskChatGPT();
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userId);

    const [interactionType, setInteractionType] = useState("summary");
    const [questionQ, setQuestionQ] = useState(1);
    const [choiceQ, setChoiceQ] = useState(4);
    const [qtdMaxQuestions, setQtdMaxQuestions] = useState(3);
    const [askingIA, setAskingIA] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [error, setError] = useState("");
    const [AIHelp, setAIHelp] = useState(true);
    const [language, setLanguage] = useState("Portuguese");
    const [projectContent, setProjectContent] = useState(contentUntilInteraction);
    const [orderGPT, setOrderGPT] = useState("assessment");

    const [questions, setQuestions] = useState([]);
    const [contentText, setContentText] = useState("");
    const [videoCase, setVideoCase] = useState([]);
    const [videoCaseGenerated, setVideoCaseGenerated] = useState(false);
    const [companySector, setCompanySector] = useState([""]);
    const [writeCompany, setWriteCompany] = useState(true);
    const [newCompany, setNewCompany] = useState("");
    const [specificChallenges, setSpecificChallenges] = useState([""])
    const [writeChallenges, setWriteChallenges] = useState(false);
    const [newChallenge, setNewChallenge] = useState("");
    const [companyObjectives, setCompanyObjectives] = useState([""])
    const [writeObjectives, setWriteObjectives] = useState(false);
    const [newObjective, setNewObjective] = useState("");

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleDirectionClick = () => {
        setMsgText(t('projectpreview:videointeraction.Gerando interação'));
        setAskingIA(true);
        handleAskGPT();
    }

    const changeQuestionQty = (questionqty) => {
        setQuestionQ(questionqty);
    }

    const changeChoicesQty = (choicesqty) => {
        setChoiceQ(choicesqty);
    }

    const handleAskGPT = async () => {
        console.log("orderGPT: " + orderGPT)
        window.scrollTo(0, 0);
        if (AIHelp) {
            setMsgText(t('projectpreview:videointeraction.Gerando interação'));
            setAskingIA(true);
            console.log("projectContent: ", projectContent)
            try {
                //showDivFloating(false)
                const res = await handleOpenAiApi({
                    subject: "",
                    selectedTopics: "",
                    questionQ,
                    choiceQ,
                    feedback: false,
                    level: "medium",
                    language,
                    orderGPT: orderGPT,
                    userInput: projectContent ? projectContent : "",
                    notConsider: "",
                    companySector: companySector,
                    specificChallenges: specificChallenges,
                    companyObjectives: companyObjectives
                });
            } catch (error) {
                setError(error);
                setAskingIA(false);
                closeVideoInteraction(false)
            }
        } else {
            //addFileManualQuestion(true)
        }
    }

    const updateProject = async () => {
        const newInteraction = {
            interaction_type: videoCaseGenerated ? "videoCase" : interactionType,
            content_text: contentText,
            interaction_time: pointInteraction,
            questions: questions,
            active: true,
            video_case: videoCase
        };

        const updatedVideoInteractions = [...projectVideoInteraction, newInteraction];

        const projectInfos = {
            actionDoc: "update",
            domain: userId,
            id: projectId,
            video_interaction: updatedVideoInteractions
        };

        try {
            const res = await updateDocument(projectInfos);
            if (res.error) {
                setError(res.error);
            } else {
                setAskingIA(false);
                closeVideoInteraction(true, updatedVideoInteractions);
            }
        } catch (error) {
            setError("An error occurred while updating the project: " + error.message);
            closeVideoInteraction(false);
        }
    };

    const changeOrderGPT = (text) => {
        setProjectContent(JSON.stringify(text))
        setOrderGPT("caseAssessment");
        setQuestionQ(1);
        setChoiceQ(4);
        setInteractionType("quiz")
        setVideoCaseGenerated(true);
    }

    const insertCompany = () => {
        const newCompanyList = [...companySector, newCompany];
        setCompanySector(newCompanyList)
        setWriteCompany(false)
        setNewCompany("")
    }

    const insertChallenge = () => {
        const newChallengeList = [...specificChallenges, newChallenge];
        setSpecificChallenges(newChallengeList)
        setWriteChallenges(false)
        setNewChallenge("")
    }

    const insertObjective = () => {
        const newObjList = [...companyObjectives, newObjective];
        setCompanyObjectives(newObjList)
        setWriteObjectives(false)
        setNewObjective("")
    }

    const handleHideInsertItems = () => {
        setWriteCompany(false)
        setWriteChallenges(false)
        setWriteObjectives(false)
    }

    useEffect(() => {
        if (videoCaseGenerated) {
            handleDirectionClick()
        }
    }, [videoCaseGenerated])

    useEffect(() => {
        originalLanguage && setLanguage(originalLanguage);
    }, [originalLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        switch (interactionType) {
            case "summary":
                setOrderGPT("summary");
                break;
            case "question":
                setOrderGPT("assessment");
                break;
            case "supplementaryContent":
                setOrderGPT("supplementaryContent");
                break;
            case "videoCase":
                setOrderGPT("videoCase");
                break;
            default:
                setOrderGPT("assessment");
        }
    }, [interactionType])

    useEffect(() => {
        if (returnGPT) {
            switch (interactionType) {
                case "summary":
                    setContentText(returnGPT);
                    break;
                case "quiz":
                    setQuestions(returnGPT);
                    break;
                case "supplementaryContent":
                    setContentText(returnGPT);
                    break
                case "videoCase":
                    setVideoCase(returnGPT);
                    changeOrderGPT(returnGPT);
                    break
                default:
                    setContentText(returnGPT);
            }
        }
    }, [returnGPT])

    useEffect(() => {
        if (errorGPT) {
            setError(errorGPT);
            setAskingIA(false);
        }
    }, [errorGPT])

    useEffect(() => {
        if ((contentText && contentText.length > 0) ||
            (questions && questions.length > 0) ||
            (videoCase && videoCase.length > 0)
        ) {
            updateProject();
        }
    }, [contentText, questions]);
    /*
        useEffect(() => {
            if (videoCase && Object.keys(videoCase).length) {
                updateProject();
            }
        }, [videoCase]);
    */
    return (
        <div>
            {askingIA ? (
                <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className="col-6 text-center mt-5">
                        <div className="spinner-grow text-primary" role="status"></div>
                        <h5 className="mt-3">
                            {`${msgText}`}
                        </h5>
                    </div>
                </div>
            ) : (
                <div className="p-0 m-0">
                    <h6 className={`m-3`}>{t('projectpreview:videointeraction.indique abaixo os parâmetros para a interação')}</h6>
                    <div className="pt-2 ms-3">
                        <div className="row align-items-center">
                            <label><h6>{t('projectpreview:videointeraction.Que tipo de interação deseja criar')}</h6></label>

                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "summary"}
                                        onChange={() => setInteractionType("summary")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Resumo')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value="text"
                                        checked={interactionType === "quiz"}
                                        onChange={() => setInteractionType("quiz")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Quiz')}</h6></label>
                                </div>
                            </div>
                            {interactionType === "quiz" &&
                                <div className={`mb-4`}>
                                    <div className="row mt-4">
                                        <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de questões')} </h6>
                                        <div className={`col-12 ${styles.qty_question}`}>
                                            {questionQ}
                                            <input
                                                className={`ms-3 ${styles.range_customize}`}
                                                type="range"
                                                min="1"
                                                max={qtdMaxQuestions}
                                                required
                                                name="qtyQuestion"
                                                value={questionQ}
                                                onChange={(e) => changeQuestionQty(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <p></p>

                                    <div className="row d-flex align-items-center mt-4">
                                        <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de alternativas')} </h6>
                                        <div className="col-12">
                                            <select
                                                className={styles.form_parameters}
                                                required
                                                name="qtyChoice"
                                                value={choiceQ}
                                                onChange={(e) => changeChoicesQty(e.target.value)}>
                                                <option value="">{`Selecione uma opção`}</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "supplementaryContent"}
                                        onChange={() => setInteractionType("supplementaryContent")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Conteúdo')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "videoCase"}
                                        onChange={() => setInteractionType("videoCase")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Case')}</h6></label>
                                </div>
                            </div>
                            {interactionType === "videoCase" &&
                                <div>
                                    <div className="mb-4">
                                        <div className="row mt-4">
                                            <h6 className="col-12">{t('projectpreview:videointeraction.Setores da cia')} </h6>
                                            {companySector && companySector.map((sector, i) => (
                                                <div>
                                                    <div className="col-12">
                                                        <h5 className={`${styles.optionCase_texts}`}>{sector}</h5>
                                                    </div>
                                                    {companySector.length === i + 1 && writeCompany && (
                                                        <div className="row">
                                                            <div className="d-flex align-items-center col-10">
                                                                <div className={`ps-2 `}>
                                                                    <form>
                                                                        <label>
                                                                            <input
                                                                                className={styles.form_field}
                                                                                type="text"
                                                                                required
                                                                                name="companySector"
                                                                                placeholder={t('projectpreview:videointeraction.Insira setor')}
                                                                                value={newCompany}
                                                                                onChange={(e) => setNewCompany(e.target.value)}
                                                                            />
                                                                        </label>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <button className={`${styles.closeTopic}`}><i className={`bi bi-x-lg d-flex justify-content-center`} onClick={handleHideInsertItems}></i></button>
                                                                <button className={`${styles.createTopic}`}><i className={`bi bi-check-lg d-flex`} onClick={insertCompany}></i></button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {companySector.length === 0 || (companySector.length === i + 1 && companySector.length < 4) && (
                                                        <div className={styles.btn_plus} onClick={() => { setWriteCompany(true) }}>
                                                            <div className={`mt-3 ${styles.icon_plus}`}>
                                                                <i className="bi bi-plus-circle"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>))
                                            }
                                        </div>

                                        <div className="row mt-4">
                                            <h6 className="col-12">
                                                {t('projectpreview:videointeraction.Desafios')}
                                            </h6>
                                            {specificChallenges && specificChallenges.map((challenge, i) => (
                                                <div>
                                                    <div className="col-12">
                                                        <h5 className={`${styles.optionCase_texts} me-1`}>{challenge}</h5>
                                                    </div>
                                                    {specificChallenges.length === i + 1 && writeChallenges && (
                                                        <div className="row">
                                                            <div className="d-flex align-items-center col-10">
                                                                <div className={`ps-2 `}>
                                                                    <form>
                                                                        <label>
                                                                            <input
                                                                                className={styles.form_field}
                                                                                type="text"
                                                                                required
                                                                                name="specificChallenge"
                                                                                placeholder={t('projectpreview:videointeraction.Insira desafios')}
                                                                                value={newChallenge}
                                                                                onChange={(e) => setNewChallenge(e.target.value)}
                                                                            />
                                                                        </label>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <button className={`${styles.closeTopic}`}><i className={`bi bi-x-lg d-flex justify-content-center`} onClick={handleHideInsertItems}></i></button>
                                                                <button className={`${styles.createTopic}`}><i className={`bi bi-check-lg d-flex`} onClick={insertChallenge}></i></button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {specificChallenges.length === 0 || (specificChallenges.length === i + 1 && specificChallenges.length < 4) && (
                                                        <div className={styles.btn_plus} onClick={() => { setWriteChallenges(true) }}>
                                                            <div className={`mt-3 ${styles.icon_plus}`}>
                                                                <i className="bi bi-plus-circle"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>))
                                            }
                                        </div>

                                        <div className="row mt-4">
                                            <h6 className="col-12">
                                                {t('projectpreview:videointeraction.Objetivos')} 
                                            </h6>
                                            {companyObjectives && companyObjectives.map((obj, i) => (
                                                <div>
                                                    <div className="col-12">
                                                        <h5 className={`${styles.optionCase_texts} me-1`}>{obj}</h5>
                                                    </div>
                                                    {companyObjectives.length === i + 1 && writeObjectives && (
                                                        <div className="row">
                                                            <div className="d-flex align-items-center col-10">
                                                                <div className={`ps-2 `}>
                                                                    <form>
                                                                        <label>
                                                                            <input
                                                                                className={styles.form_field}
                                                                                type="text"
                                                                                required
                                                                                name="companyObjectives"
                                                                                placeholder={t('projectpreview:videointeraction.Insira objetivo')}
                                                                                value={newObjective}
                                                                                onChange={(e) => setNewObjective(e.target.value)}
                                                                            />
                                                                        </label>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <button className={`${styles.closeTopic}`}><i className={`bi bi-x-lg d-flex justify-content-center`} onClick={handleHideInsertItems}></i></button>
                                                                <button className={`${styles.createTopic}`}><i className={`bi bi-check-lg d-flex`} onClick={insertObjective}></i></button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {companyObjectives.length === 0 || (companyObjectives.length === i + 1 && companyObjectives.length < 4) && (
                                                        <div className={styles.btn_plus} onClick={() => { setWriteObjectives(true) }}>
                                                            <div className={`mt-3 ${styles.icon_plus}`}>
                                                                <i className="bi bi-plus-circle"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>}
                        </div>

                    </div>
                    <div>
                        <button className={`mt-5 ms-3 mb-4 ${styles.button_quizz} ${styles.btn_style}`} onClick={() => handleDirectionClick()}>
                            {`${t('projectpreview:videointeraction.Gerar interação')}`}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoInteraction;