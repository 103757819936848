//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './ExportScorm.module.css'
//React Hooks
import { useEffect, useState, useRef } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
//External libs
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
//SCORM Files
import { useGenerateHTML } from '../hooks/useGenerateHTML';
import { useGenerateManifest } from '../hooks/useGenerateManifest';
import { useGenerateManifest2004 } from '../hooks/useGenerateManifest2004';
import { useGenerateAPIWrapper } from '../hooks/useGenerateAPIWrapper';
import { useGenerateSCOFunctions } from '../hooks/useGenerateSCOFunctions';
import { useGenerateScormFunctions } from '../hooks/useGenerateScormFunctions';
import { useGenerateContentFunctions } from '../hooks/useGenerateContentFunctions';
import { useGenerateImsmd } from '../hooks/useGenerateImsmd';
import { useGenerateImscp } from '../hooks/useGenerateImscp';
import { useGenerateIms } from '../hooks/useGenerateIms';
import { useGenerateAdlcp } from '../hooks/useGenerateAdlcp';

const ExportScorm = ({ project, exportFormat, handleCloseExportProject }) => {

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const [hasAssessment, setHasAssessment] = useState();
    const [scormVersion, setScormVersion] = useState("scorm12");
    const [conclusionScorm, setConclusionScorm] = useState("score");
    const [methodScorm, setMethodScorm] = useState("passed/failed");
    const [passScore, setPassScore] = useState(70);
    const [publishFirebase, setPublishFirebase] = useState(false);
    const [exportingProject, setExportingProject] = useState(false);
    const [listUrlSlides, setListUrlSlides] = useState(project.listUrlSlides);
    const [fileMessange, setFileMessange] = useState(t('projectpreview:exportscorm.projeto sendo exportado'))

    const { generateHTML, saveHTMLToFirebase } = useGenerateHTML();
    const { generateManifest } = useGenerateManifest();
    const { generateManifest2004 } = useGenerateManifest2004();
    const { generateAPIWrapper } = useGenerateAPIWrapper();
    const { generateSCOFunctions } = useGenerateSCOFunctions();
    const { generateImsmd } = useGenerateImsmd();
    const { generateIms } = useGenerateIms();
    const { generateAdlcp } = useGenerateAdlcp();
    const { generateImscp } = useGenerateImscp();
    const { generateScormFunctions } = useGenerateScormFunctions();
    const { generateContentFunctions } = useGenerateContentFunctions();

    const formatExport = exportFormat

    const removeSpecialChar = (str) => {
        str = removeAccents(str);
        str = str.replace(/ /g, "_");
        str = str.replace(/[&\/\\#,+()$~%'":*?<>{}!@¨]/g, '');
        str = str.toLowerCase();
        return str;
    }

    const removeAccents = (text) => {
        const accents = {
            'á': 'a',
            'à': 'a',
            'â': 'a',
            'ã': 'a',
            'ä': 'a',
            'é': 'e',
            'è': 'e',
            'ê': 'e',
            'ë': 'e',
            'í': 'i',
            'ì': 'i',
            'î': 'i',
            'ï': 'i',
            'ó': 'o',
            'ò': 'o',
            'ô': 'o',
            'õ': 'o',
            'ö': 'o',
            'ú': 'u',
            'ù': 'u',
            'û': 'u',
            'ü': 'u',
            'ñ': 'n',
            'ç': 'c',
            '&': 'and',
        };

        return text
            .split('')
            .map((char) => accents[char] || char)
            .join('');
    };

    const subjectWithouSpecialChar = removeSpecialChar(project.subject);

    const handleSaveZipFile = async () => {
        setExportingProject(true);
        const zip = new JSZip();
        const stylesCSS = project.cssTemplate;
        const subtitles = project.subtitle;
        const imageContents = project.listUrlSlides ? await downloadImages() : null;
        const videofile = project.videoUrl ? await downloadVideo(project.videoUrl) : null;
        const htmlString = handleGenerateHTML("generatehtml", imageContents, videofile);
        zip.file('index.html', htmlString);
        zip.file('/css/styles.css', stylesCSS);

        if (imageContents && imageContents.length > 0) {
            imageContents.forEach((image) => {
                const uint8Array = new Uint8Array(image.content);
                zip.file(`/img/${image.name}`, uint8Array, { binary: true });
            });
        }
        if (videofile) {
            zip.file(`/video/${videofile.name}`, videofile.content, { binary: true });
            zip.file('/video/subtitle.vtt', subtitles);
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${subjectWithouSpecialChar}_HTML.zip`);
        });
        handleCloseExportProject(true)
        setExportingProject(false);
    };

    const handleGenerateURL = async () => {
        const imageContents = project.listUrlSlides ? await downloadImages() : null;
        const videofile = project.videoUrl ? await downloadVideo(project.videoUrl) : null;
        const projectInfos = {
            css: project.cssTemplate,
            templateName: project.nameTemplate,
            projectName: project.projectName,
            projectDestiny: "generatehtml",
            introAssessment: project.projectIntro,
            projectContent: project.projectContent,
            assessment: project.assessment,
            language: project.language,
            score: passScore,
            conclusion: conclusionScorm,
            method: methodScorm,
            imagesList: imageContents,
            urlLogo: project.urlLogo,
            showSlides: project.showSlides,
            showTogether: project.showTogether,
            videoFile: videofile,
            showVideo: project.showVideo,
            showVideoTogether: project.showVideoTogether,
            showProgress: project.showProgress,
            menuItems: project.menuItems,
            menuFreeNavigation: project.menuFreeNavigation,
            hasAssessment: hasAssessment,
            includeCase: project.includeCase,
            includeContent: project.includeContent,
            includeSlides: project.includeSlides,
            includeVideo: project.includeVideo,
            subtitle: project.subtitle,
            srclang: project.srclang,
            label: project.label,
            videoInteraction: project.projectVideoInteraction,
            interactionTimes: project.interactionTimes,
        }
        const projectURL =  await saveHTMLToFirebase(projectInfos);
        console.log("projectURL", projectURL)
    }

    const handleGenerateHTML = (destiny, imageContents, videofile) => {

        const projectInfos = {
            css: project.cssTemplate,
            templateName: project.nameTemplate,
            projectName: project.projectName,
            projectDestiny: destiny,
            introAssessment: project.projectIntro,
            projectContent: project.projectContent,
            assessment: project.assessment,
            language: project.language,
            score: passScore,
            conclusion: conclusionScorm,
            method: methodScorm,
            imagesList: imageContents,
            urlLogo: project.urlLogo,
            showSlides: project.showSlides,
            showTogether: project.showTogether,
            videoFile: videofile,
            showVideo: project.showVideo,
            showVideoTogether: project.showVideoTogether,
            showProgress: project.showProgress,
            menuItems: project.menuItems,
            menuFreeNavigation: project.menuFreeNavigation,
            hasAssessment: hasAssessment,
            includeCase: project.includeCase,
            includeContent: project.includeContent,
            includeSlides: project.includeSlides,
            includeVideo: project.includeVideo,
            subtitle: project.subtitle,
            srclang: project.srclang,
            label: project.label,
            videoInteraction: project.projectVideoInteraction,
            interactionTimes: project.interactionTimes,
        }
        return generateHTML(projectInfos);
    }

    const handleGenerateScorm = async () => {
        setExportingProject(true)
        const zip = new JSZip();

        const imageContents = project.listUrlSlides ? await downloadImages() : null;
        const videofile = project.videoUrl ? await downloadVideo(project.videoUrl) : null
        const htmlString = handleGenerateHTML(scormVersion, imageContents, project.videoUrl);

        const stylesCSS = project.cssTemplate;
        const subtitles = project.subtitle;

        const js1 = generateAPIWrapper();
        const js2 = generateSCOFunctions();
        const scormFile1 = generateImsmd();
        const scormFile2 = generateImscp();
        const scormFile3 = generateIms();
        const scormFile4 = generateAdlcp();

        zip.file('imsmd_rootv1p2p1.xsd', scormFile1);
        zip.file('imscp_rootv1p1p2.xsd', scormFile2);
        zip.file('ims_xml.xsd', scormFile3);
        zip.file('adlcp_rootv1p2.xsd', scormFile4);
        zip.file('/scorm/APIWrapper.js', js1);
        zip.file('/scorm/SCOFunctions.js', js2);
        zip.file('index.html', htmlString);
        zip.file('/css/styles.css', stylesCSS);

        const manifestString = generateManifest(project.subject);
        zip.file('imsmanifest.xml', manifestString);

        if (imageContents.length > 0) {
            imageContents.forEach((image) => {
                zip.file(`/img/${image.name}`, image.content, { binary: true });
            });
        }

        if (videofile) {
            zip.file(`/video/${videofile.name}`, videofile.content, { binary: true });
            zip.file('/video/subtitle.vtt', subtitles);
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${subjectWithouSpecialChar}_SCO.zip`);
        });
        handleCloseExportProject(true)
        setExportingProject(false)
    };

    const downloadImages = async () => {

        try {
            const response = await axios.get('https://us-central1-quizzai-4b3cd.cloudfunctions.net/importSlidesPPT', {
                params: {
                    listUrlSlides: JSON.stringify(listUrlSlides)
                }
            });

            const imageContentsReturn = response.data.images;

            const convertImageContent = () => {
                const array1 = imageContentsReturn.map(item => {
                    const arrayBuffer = new ArrayBuffer(item.content.data.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < item.content.data.length; i++) {
                        uint8Array[i] = item.content.data[i];
                    }
                    return { name: item.name, content: arrayBuffer };
                });
                return array1;
            };

            const imageContents = convertImageContent();
            return imageContents;
        } catch (error) {
            console.error("Erro no download das imagens:", error);
            throw error;
        }
    };

    const downloadVideo = async (url) => {
        const videoUrl = url;

        try {
            const response = await fetch(videoUrl, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error('Erro na solicitação');
            }

            const arrayBuffer = await response.arrayBuffer();
            const imageName = `video.mp4`;
            return { name: imageName, content: new Uint8Array(arrayBuffer) };
        } catch (error) {
            console.error('Erro na solicitação:', error);
            throw error;
        }
    };

    const handleGenerateScorm2004 = async () => {
        setExportingProject(true)
        const zip = new JSZip();

        const imageContents = project.listUrlSlides ? await downloadImages() : null;
        const videofile = project.videoUrl ? await downloadVideo(project.videoUrl) : null
        const htmlString = handleGenerateHTML(scormVersion, imageContents, project.videoUrl);
        const stylesCSS = project.cssTemplate;
        const subtitles = project.subtitle;

        const js1 = generateContentFunctions();
        const js2 = generateScormFunctions();

        const manifestString = generateManifest2004(project.subject);
        zip.file('imsmanifest.xml', manifestString);
        zip.file('index.html', htmlString);
        zip.file('/css/styles.css', stylesCSS);
        zip.file('/scorm/contentfunctions.js', js1);
        zip.file('/scorm/scormfunctions.js', js2);

        if (imageContents.length > 0) {
            imageContents.forEach((image) => {
                zip.file(`/img/${image.name}`, image.content, { binary: true });
            });
        }

        if (videofile) {
            zip.file(`/video/${videofile.name}`, videofile.content, { binary: true });
            zip.file('/video/subtitle.vtt', subtitles);
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${subjectWithouSpecialChar}_SCO.zip`);
        });
        handleCloseExportProject(true)
        setExportingProject(false)
    }

    useEffect(() => {
        if (project.assessment && project.assessment.length > 0) {
            setHasAssessment(true)
            setConclusionScorm("score")
            setMethodScorm("passed/failed")
        } else {
            setHasAssessment(false)
            setConclusionScorm("conclusion")
            setMethodScorm("completed")
        }
    }, [project])

    useEffect(() => {
        if (conclusionScorm === "score") {
            setMethodScorm("passed/failed")
        } else {
            setMethodScorm("completed")
        }
    }, [conclusionScorm])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            {exportingProject
                ? <div className={`row d-flex justify-content-center align-items-center pt-3 pb-3 mt-5`}>
                    <div className="text-center">
                        <div><h5>{fileMessange}</h5></div>
                    </div>
                    <div className="ps-1 mt-2 text-center">
                        <div className={`spinner-grow text-primary ${styles.spinner_customize}`} role="status"></div>
                    </div>
                </div>
                : formatExport === "scorm"
                    ? <div className="ms-0 mt-4">
                        <div className={`row ms-2 ${styles.texts_format}`}>
                            <div className="col-auto">
                                <div className="form-check ">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value="12"
                                        checked={scormVersion === "scorm12"}
                                        onChange={() => setScormVersion("scorm12")}
                                        style={{ border: "1px solid #ced4da" }}
                                    />
                                    <label className="form-check-label">{`SCORM 1.2`}</label>
                                </div>
                            </div>
                            <div className={`col-auto `}>
                                <div className="form-check">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        disabled={false}
                                        value="2004"
                                        checked={scormVersion === "scorm2004"}
                                        onChange={() => setScormVersion("scorm2004")}
                                        style={{ border: "1px solid #ced4da" }}
                                    />
                                    <label className="form-check-label">{`SCORM 2004`}</label>
                                </div>
                            </div>
                        </div>
                        <div className={`row d-flex align-items-center ms-2 mt-4 ${styles.texts_format}`}>
                            <h6 className="col-auto">{t('projectpreview:exportscorm.registro conclusão SCORM')}</h6>
                            <div className="col-auto">
                                <select
                                    className={`${styles.form_parameters}`}
                                    required
                                    name="conclusionScorm"
                                    value={conclusionScorm}
                                    onChange={(e) => setConclusionScorm(e.target.value)}>
                                    <option value="score" disabled={!hasAssessment}>{t('projectpreview:exportscorm.Nota obtida')}</option>
                                    <option value="conclusion">{t('projectpreview:exportscorm.Progresso completo')}</option>
                                </select>
                            </div>
                        </div>
                        <div className={`row d-flex align-items-center ms-2 mt-4 ${styles.texts_format}`}>
                            <h6 className="col-auto">{t('projectpreview:exportscorm.método salvar SCORM')}</h6>
                            <div className="col-auto">
                                <select
                                    className={`${styles.form_parameters} `}
                                    required
                                    name="methodScorm"
                                    disabled={conclusionScorm === "score" ? false : true}
                                    value={methodScorm}
                                    onChange={(e) => setMethodScorm(e.target.value)}>
                                    <option value="passed" disabled={!hasAssessment}>passed / failed</option>
                                    <option value="completed">completed / incomplete</option>
                                </select>
                            </div>
                        </div>
                        <div className={`row mt-4 ms-2 d-flex align-items-center ${styles.texts_format}`}>
                            <h6 className="col-auto "> {t('projectpreview:exportscorm.aproveitamento mínimo')}</h6>
                            <div className="col-auto">
                                <input
                                    className={`${styles.form_field}`}
                                    type="number"
                                    required
                                    name="score"
                                    disabled={conclusionScorm === "score" ? false : true}
                                    value={passScore}
                                    onChange={(e) => { setPassScore(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className={`mt-4 ms-2 ${styles.texts_format}`}>
                            <h6>{t('projectpreview:exportscorm.Observação SCORM ZIP')}</h6>
                        </div>
                        <div className="row d-flex ms-2  mt-4 ">
                            <button className={`${styles.btn_style}`} onClick={scormVersion === "scorm12" ? () => handleGenerateScorm() : () => handleGenerateScorm2004()}>{t('projectpreview:exportscorm.Exportar e baixar arquivo')}</button>
                        </div>
                    </div>
                    : <div>
                        <div className="mt-4 ms-3">
                            <h6 className={`${styles.texts_format}`}>{t('projectpreview:exportscorm.HTML baixado ZIP')}</h6>
                        </div>
                        <div className="row d-flex ms-3 mt-4 ">
                            <button className={`${styles.btn_style}`} onClick={() => handleSaveZipFile()}>{t('projectpreview:exportscorm.Exportar e baixar arquivo')}</button>
                        </div>
                        <div className="row d-flex ms-3 mt-4 ">
                            <button className={`${styles.btn_style}`} onClick={() => handleGenerateURL()}>Publicar LMS</button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ExportScorm