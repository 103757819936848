
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './ProjectPreview.module.css'
import defaultStyles from '../templates/default.module.css'
import aquaStyles from '../templates/aqua.module.css'
import blueStyles from '../templates/blue.module.css'
import cleanDarkStyles from '../templates/cleanDark.module.css'
import cleanLightStyles from '../templates/cleanLight.module.css'
import cleanNeutralStyles from '../templates/cleanNeutral.module.css'
import coralStyles from '../templates/coral.module.css'
import freshStyles from '../templates/fresh.module.css'
import greenStyles from '../templates/green.module.css'
import pastelStyles from '../templates/pastel.module.css'
import purpleRainStyles from '../templates/purpleRain.module.css'
import quizAIStyles from '../templates/quizai.module.css'
import Dropdown from 'react-bootstrap/Dropdown';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from "firebase/storage";
import { storage } from "../firebase/config";
import { useAuthValue } from '../context/AuthContext';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useFetchProjects } from '../hooks/useFetchProjects';
//React Hooks
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useCreateProject } from '../hooks/useCreateProject';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
//Dev components
import ProjectTemplate from './ProjectTemplate';
import DialogBox from './DialogBox';
import AssessmentQuestions from './AssessmentQuestions';
import AssessmentParameters from './AssessmentParameters';
import ProjectExport from './ProjectExport';
import ContentParameters from './ContentParameters';
import AssessmentContent from './AssessmentContent';
import AssessmentCase from './AssessmentCase';
import VideoInteraction from './VideoInteraction';
import VideoInteractionObject from './VideoInteractionObject';
import { de } from 'date-fns/locale';
import { set } from 'date-fns';
//import subTitleVideo from '../subtitle/Segurança da Informação.srt'

const ProjectPreview = ({ id, projectSelected, returnHome, reviewProject, limitAvaliable, projectType }) => {

    var search = "";
    const { user } = useAuthValue();
    var { document: project, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", user.uid, "Folder", id);
    const { documents: templates, loadingTemplate, errorTemplate } = useFetchProjects("Templates", search);
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains", user.uid);
    const { createProject } = useCreateProject();
    const { handleOpenAiApi, returnGPT, textTranslated, loading, errorGPT, generateGPT } = useAskChatGPT();

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const [userId, setUserId] = useState(user.uid);

    const [projectLoading, setProjectLoading] = useState(true);
    const [editProject, setEditProject] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);
    const [showOptions, setShowOptions] = useState(true);
    const [showProgress, setShowProgress] = useState(true);
    const [showSubLogo, setShowSubLogo] = useState(false);
    const [showSubDeleteLogo, setShowSubDeleteLogo] = useState(false);
    const [showSubProgressBar, setShowSubProgressBar] = useState(false);
    const [disableItemFloat, setDisableItemFloat] = useState(false);
    const [menuFreeNavigation, setMenuFreeNavigation] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [slidesHeight, setSlidesHeight] = useState(800);
    const [anyChange, setAnyChange] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [templateTon, setTemplateTon] = useState("white");
    const [AIHelp, setAIHelp] = useState(true);
    const [creatingProject, setCreatingProject] = useState(false);
    const [idProject, setIdProject] = useState(id);
    const [generateQuestions, setGenerateQuestions] = useState(false);

    const [projectName, setProjectName] = useState("");
    const [urlLogo, setUrlLogo] = useState("");
    const [language, setLanguage] = useState("Portuguese");

    const [openDialog, setOpenDialog] = useState(false);
    const [msgDialog, setMsgDialog] = useState("");
    const [optionsDialog, setOptionsDialog] = useState(["OK"]);
    const [action, setAction] = useState("");

    const [showPPt, setShowPPt] = useState(false);
    const [pptPath, setPptPath] = useState(null);
    const [pptAssessmentTogether, setPptAssessmentTogether] = useState(false);
    const [pptText, setPptText] = useState("");
    const [listUrlSlides, setListUrlSlides] = useState([]);
    const [slide, setSlide] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const [slidesProgress, setSlidesProgress] = useState(0);
    const [menuItems, setMenuItems] = useState([]);
    const [visitedSlide, setVisitedSlide] = useState(1);
    const [includeSlides, setIncludeSlides] = useState(false);

    const [showVideo, setShowVideo] = useState(false);
    const [videoPath, setVideoPath] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [objectSubtitle, setObjectSubtitle] = useState(null);
    const [videoSubtitle, setVideoSubtitle] = useState({});
    const [subTitleUrl, setSubTitleUrl] = useState("");
    const [videoAssessmentTogether, setVideoAssessmentTogether] = useState(true);
    const [videoText, setVideoText] = useState("");
    const [answerAssessmentVisible, setAnswerAssessmentVisible] = useState(false);
    const [reviewVideoVisible, setReviewVideoVisible] = useState(false);
    const [videoOverlayVisible, setVideoOverlayVisible] = useState(false);
    const [includeVideo, setIncludeVideo] = useState(false);
    const [editSubtitle, setEditSubtitle] = useState(false);
    const [trackNumber, setTrackNumber] = useState(0);
    const [subtitleFile, setSubTitleFile] = useState(null);
    const [subtitleLabel, setSubtitleLabel] = useState("Português");
    const [srclangSubtitle, setSrclangSubtitle] = useState("pt");
    const [languageSubtitle, setLanguageSubtitle] = useState("pt-BR");
    const [originalLagSub, setOriginalLangSub] = useState("");
    const [showInteractionButton, setShowInteractionButton] = useState(false);

    const [fileTipMessange, setFileTipMessange] = useState(``);
    const [translatingSubtitles, setTranslatingSubtitles] = useState(false);
    const [error, setError] = useState("");
    const [subtitleTranslated, setSubtitleTranslated] = useState(false);
    const [newSubtitleLanguage, setNewSubtitleLanguage] = useState("");

    const [projectContent, setProjectContent] = useState("");
    const [showAssessmentParameters, setShowAssessmentParameters] = useState(false);
    const [projectAssessment, setProjectAssessment] = useState([]);
    const [showAssessment, setShowAssessment] = useState(false);
    const [showButtonAnsAss, setShowButtonAnsAss] = useState(false);
    const [hasBtAnAsClicked, setHasBtAnAsClicked] = useState(false);
    const [hasAssessment, setHasAssessment] = useState(false);
    const [qtdQuestions, setQtdQuestions] = useState(0);
    const [originalQtdQuestions, setOriginalQtdQuestions] = useState(0);
    const [returnIA, setReturnIA] = useState(false);
    const [returnManual, setReturnManual] = useState(false);
    const [notConsider, setNotConsider] = useState([]);
    const [IAGeneratedQuestions, setIAGeneratedQuestions] = useState(0);
    const [qtIAQuestions, setQtIAQuestions] = useState(0);
    const [newQtdIAQuestions, setNewQtdIAQuestions] = useState(0);

    const [showTemplates, setShowTemplates] = useState(false);
    const [chooseTemplate, setChooseTemplate] = useState(defaultStyles);
    const [templateId, setTemplateId] = useState("");
    const [originalTemplate, setOriginalTemplate] = useState("")
    const [cssTemplate, setCssTemplate] = useState("");

    const [showContentParameters, setShowContentParameters] = useState(false);
    const [includeContent, setIncludeContent] = useState(false);
    const [includeCase, setIncludeCase] = useState(false);
    const [contentReviewed, setContentReviewed] = useState("");
    const [contentType, setContentType] = useState("");
    const [changeContent, setChangeContent] = useState(false);

    const [pointInteraction, setPointInteraction] = useState();
    const [contentUntilInteraction, setContentUntilInteraction] = useState("");
    const [projectVideoInteraction, setProjectVideoInteraction] = useState([]);
    const [interactionTimes, setInteractionTimes] = useState([]);
    const [currentInteractionTime, setCurrentInteractionTime] = useState(0);
    const [showVideoInteractionObject, setShowVideoInteractionObject] = useState(false);
    const [showVideoInteraction, setShowVideoInteraction] = useState(false);
    const [controlVideoInteraction, setControlVideoInteraction] = useState(false);

    const [userText, setUserText] = useState("");

    const [showExportProject, setShowExportProject] = useState(false)

    const [projectInfos, setProjectInfos] = useState([]);

    const [showNewAssessment, setShowNewAssessment] = useState(false);

    const carouselRef = useRef(null);
    const buttonRef = useRef();
    const videoRef = useRef(null);
    const interactionTimesInSeconds = useRef([]);
    const triggeredTimes = useRef(new Set());
    const assessmentDivRef = useRef(null);

    const handleChangeContent = (change) => {
        setChangeContent(change)
    }

    const handleDropdownToggle = (isOpen) => {
        setDropdownOpen(isOpen);
    };

    const handleReturnHome = () => {
        returnHome();
    }

    const nextButtonCarousel = () => {
        setSlide(slide + 1)
        if (slide + 1 >= visitedSlide) {
            setVisitedSlide(visitedSlide + 1)
        }
        const nextButton = document.querySelector('.nextButton').click();
    }

    const prevButtonCarousel = () => {
        setSlide(slide - 1)
        const prevButton = document.querySelector('.prevButton').click();
    }

    const changeMenuPage = (index) => {
        setSlide(index);
    }

    const handleIncludeLogo = () => {
        const inputElement = document.createElement('input');
        inputElement.type = 'file';

        inputElement.addEventListener('change', (changeEvent) => {
            const selectedFiles = changeEvent.target.files;
            for (const file of selectedFiles) {
                uploadLogo(file);
            }
        });

        inputElement.click();
    }

    const handleAnswerAssessment = () => {
        setShowAssessment(!showAssessment)
        setShowVideo(false)
    }

    const handleReviewVideo = () => {
        if (videoRef.current) {
            setAnswerAssessmentVisible(false)
            setReviewVideoVisible(false)
            setVideoOverlayVisible(false)
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const uploadLogo = async (file) => {
        setUploadingFile(true);
        await handleDeleteLogo();

        const forcedFileName = 'logo';
        const originalFileNameParts = file.name.split('.');
        const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
        const newFileName = `${forcedFileName}${fileExtension}`;

        try {
            if (file) {
                const maxSize = 200 * 1024;
                if (file.size > maxSize) {
                    setOptionsDialog(['Ok']);
                    const msg = (t('projectpreview:messages.excede 200Kb'));
                    setMsgDialog(msg);
                    setAction('error');
                    return;
                }
                const storageRef = ref(storage, `logos/${userId}/${idProject}/${newFileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file, { maxSize: 100 * 1024 });

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setProgresspercent(progress);
                        if (progress === 100) {
                            setTimeout(() => {
                                getDownloadURL(storageRef)
                                    .then((url) => {
                                        setProgresspercent(0);
                                        saveLogoProject(url);
                                        setUrlLogo(url);
                                        setAnyChange(true);
                                    })
                                    .catch((error) => {
                                        console.error('Erro ao obter a URL do arquivo:', error);
                                    });
                            }, 2000);
                        }
                    },
                    (error) => {
                        setOptionsDialog('Ok')
                        setAction('error');
                        if (error.code === 'storage/canceled') {
                            const msg = (t('projectpreview:messages.upload cancelado'));
                            setMsgDialog(msg);
                        } else if (error.code === 'storage/unknown') {
                            const msg = (t('projectpreview:messages.erro upload'));
                            setMsgDialog(msg);
                        }
                    }
                );
            }
        } catch (error) {
            console.error('Erro geral:', error);
        }
    }

    const handleDeleteLogo = async () => {
        setUrlLogo("");
        setAnyChange(true);
        const pathToDelete = `logos/${userId}/${idProject}`;

        try {
            const storageRefToDelete = ref(storage, pathToDelete);
            const items = await listAll(storageRefToDelete);
            const deletePromises = items.items.map(async (item) => {
                await deleteObject(item);
            });
            await Promise.all(deletePromises);
            setUrlLogo("");
            saveLogoProject("");
        } catch (error) {
            console.error('Error deleting folder:', error.message);
        }
    }

    const saveLogoProject = (url) => {
        const projectInfos = {
            actionDoc: "update",
            domain: userId,
            id: idProject,
            logo_url: url,
        }
        const res = updateDocument(projectInfos);
    }

    const updateProject = () => {

        const projectInfos = {
            actionDoc: "update",
            domain: userId,
            id: idProject,
            project_name: projectName,
            show_progress: showProgress,
            menu_items: menuItems,
            menu_free_navigation: menuFreeNavigation,
            template_id: templateId,
            assessment: projectAssessment,
            ppt_assessent_together: pptAssessmentTogether,
            video_assessment_together: videoAssessmentTogether,
            content_generated: projectContent,
            project_content: contentReviewed,
            IA_generated_questions: newQtdIAQuestions,
            subtitle: objectSubtitle,
            project_language: language,
            subtitle_language: newSubtitleLanguage,
            video_interaction: projectVideoInteraction,
        }
        const res = updateDocument(projectInfos);
        setQtIAQuestions(0)
    }

    const createFBProject = (text) => {
        const projectInfos = {
            actionDoc: "insert",
            idProject: idProject,
            introAssessment: "",
            content_generated: userText ? userText : projectContent,
            projectName: projectName,
            projectDescription: "",
            projectContent: userText ? userText : contentReviewed,
            includeContent: includeContent,
            includeCase: includeCase,
            includeSlides: false,
            presentTogether: true,
            projectFolder: "",
            assessment: projectAssessment,
            language: language,
            subject: projectName,
            pathToSlides: "",
            videoUrl: "",
            videoPath: "",
            includeVideo: false,
            presentVideoTogether: false,
            textPpt: "",
            videoTranscript: "",
            listUrlSlides: [],
            show_progress: true,
            menuItems: [],
            showProgress: false,
            menu_free_navigation: false,
            template_id: "quizai",
            projectFolder: "Folder",
            IA_generated_questions: qtIAQuestions,
        }
        const res = createProject(projectInfos);
        setDisableItemFloat(false)
        setCreatingProject(false)
    }

    const projectInfosExport = () => {

        const project = {
            subject: projectName,
            cssTemplate: cssTemplate,
            projectName: projectName,
            projectIntro: "",
            projectContent: projectContent ? projectContent : "",
            assessment: projectAssessment,
            language: language,
            score: 70,
            conclusion: "",
            method: "",
            listUrlSlides: listUrlSlides,
            urlLogo: urlLogo,
            showSlides: true,
            videoFile: videoPath,
            videoUrl: videoUrl,
            showVideo: true,
            showTogether: pptAssessmentTogether,
            showVideoTogether: videoAssessmentTogether,
            showProgress: showProgress,
            menuItems: menuItems,
            menuFreeNavigation: menuFreeNavigation,
            includeCase: includeCase,
            includeContent: includeContent,
            includeSlides: includeSlides,
            includeVideo: includeVideo,
            hasAssessment: hasAssessment,
            subtitle: subtitleFile,
            srclang: srclangSubtitle,
            label: subtitleLabel,
            projectVideoInteraction: projectVideoInteraction,
            interactionTimes: interactionTimes,
        }
        setProjectInfos(project);
    }

    const changeProjectName = (name) => {
        setAnyChange(true);
        setProjectName(name);
    }

    const changeMenuItemName = (index, name) => {
        setAnyChange(true);
        const menuChange = [...menuItems];

        if (index >= 0 && index < menuChange.length) {
            menuChange[index] = name;
        } else {
            console.error("O índice está fora do intervalo do array de menuItems.");
        }

        setMenuItems(menuChange);
    }

    const changeShowProgress = () => {
        setAnyChange(true)
        setShowProgress(!showProgress);
        setShowSubProgressBar(false);
    }

    const changeProject = () => {
        //window.scrollTo(0, 0);
        setMenuActive(true);
        setEditProject(!editProject);
        totalSlides > 0 && setShowPPt(true);
        videoUrl && setShowVideo(true);
        setShowAssessment(true);
    }

    const changeTemplate = () => {
        window.scrollTo(0, 0);
        setMenuActive(true);
        setShowTemplates(true);
    }

    const changeMenuFreeNavigation = () => {
        setMenuFreeNavigation(!menuFreeNavigation)
        setAnyChange(true)
    }

    const changeVideoInteraction = (option, projectUpdated, deleteInteraction) => {
        setAnyChange(option)
        setMenuActive(true);
        setProjectVideoInteraction(projectUpdated)
        if (deleteInteraction) {
            console.log("deleteInteraction ", deleteInteraction)
            updateProject();
        }
    }

    const addQuizz = () => {
        if (limitAvaliable) {
            window.scrollTo(0, 0);
            setMenuActive(true);
            setShowAssessmentParameters(true);
        } else {
            const msg = (t('projectpreview:messages.limite projetos mes'));
            setMsgDialog(msg)
            setOptionsDialog(["Ok"])
            setAction('error');
        }
    }

    const exportProject = () => {
        window.scrollTo(0, 0);
        setMenuActive(true);
        setShowExportProject(true);
    }

    const closeMenu = () => {
        setEditSubtitle(false);
        setMenuActive(false);
        setEditProject(false);
        setShowTemplates(false);
        setShowVideoInteraction(false);
        setShowContentParameters(false);
        setAnyChange(false)
        setShowAssessmentParameters(false)
        setShowExportProject(false);
        if ((includeSlides || includeVideo) && !pptAssessmentTogether && !videoAssessmentTogether && !hasBtAnAsClicked) {
            setShowAssessment(false)
        }
        if (hasBtAnAsClicked && !pptAssessmentTogether) {
            setShowPPt(false)
        }
    }

    const closeMenuChange = () => {
        setEditSubtitle(false);
        setMenuActive(false);
        setEditProject(false);
        setShowTemplates(false);
        setAnyChange(false);
        setShowAssessmentParameters(false);
        setShowExportProject(false);
        setChangeContent(false);
        if ((includeSlides || includeVideo) && !pptAssessmentTogether && !videoAssessmentTogether) {
            setShowAssessment(false);
        }
        if (videoRef.current && !showVideoInteractionObject) {
            videoRef.current.play();
        }
        creatingProject ? createFBProject() : updateProject()
    }

    const handleChangeTemplate = (templateid) => {
        setTemplateId(templateid);
        const stylesMap = {
            default: defaultStyles,
            aqua: aquaStyles,
            blue: blueStyles,
            cleanDark: cleanDarkStyles,
            cleanLight: cleanLightStyles,
            cleanNeutral: cleanNeutralStyles,
            coral: coralStyles,
            fresh: freshStyles,
            green: greenStyles,
            pastel: pastelStyles,
            purpleRain: purpleRainStyles,
            quizai: quizAIStyles,
        };
        document.body.className = '';
        const newTemplate = stylesMap[templateid];
        setChooseTemplate(newTemplate);
        document.body.classList.add(stylesMap[templateId].body_project);
        if (originalTemplate != templateid) {
            setAnyChange(true)
        } else {
            setAnyChange(false)
        }
    }

    const actionScript = () => {
        setOpenDialog(false)
        setUploadingFile(false)
        setMsgDialog("");
    }

    const actionMsgDialog = (msg) => {
        setOpenDialog(false)
        setMsgDialog(msg)
        setOptionsDialog(["Ok"])
        setAction('error');
    }

    const translateIAGenerated = (translate) => {
        setObjectSubtitle(translate)
    }

    const assessmentIAGenerated = (assessment, qt) => {

        setQtIAQuestions(qt)
        if (qtdQuestions > 0) {
            const mergedAssessment = [...projectAssessment, ...assessment];
            changeAssessment(mergedAssessment, true);
        } else {
            changeAssessment(assessment, true)
        }
        setShowAssessmentParameters(false)
        setGenerateQuestions(false)
        setReturnIA(true)
        setShowAssessment(true)
        setMenuActive(true)
        setNotConsider([])
        qt = Number(qt);

        setNewQtdIAQuestions(qt + IAGeneratedQuestions)
    }

    const changeAssessment = (assessment, setp) => {
        if (assessment != projectAssessment) {
            setAnyChange(true)
            setp && setProjectAssessment(assessment)
        }
    }

    const changeIdProject = (id, name, aihelp, content, language, type, usercontent) => {
        setIdProject(id)
        setProjectName(name)
        setAIHelp(aihelp)
        setEditProject(true)
        setShowNewAssessment(false)
        setMenuActive(true)
        setAnyChange(false)
        setCreatingProject(true)
        setShowProgress(false)
        setMenuItems([])
        setMenuFreeNavigation(false)
        setTemplateId("quizai")
        setVideoAssessmentTogether(false)
        setPptAssessmentTogether(false)
        setProjectContent(content)
        setLanguage(language)
        setContentType(type)
        setIncludeContent(type === "text")
        setIncludeCase(type === "case")
        setChooseTemplate(defaultStyles)
        setShowContentParameters(false)
        setUserText(usercontent)

        if (aihelp) {
            setGenerateQuestions(true)
        } else if (contentType === "assessment") {
            setShowAssessment(true)
            const newQuestion = [{
                alternatives: [
                    { option: "a", text: "" },
                    { option: "b", text: "" },
                    { option: "c", text: "" },
                    { option: "d", text: "" },
                ],
                ask_question: "",
                correct_answer: "",
                feedback: ""
            }]
            setProjectAssessment(newQuestion)
        }
    }

    const addFileManualQuestion = (b) => {
        setEditProject(b)
        setShowAssessmentParameters(!b)
        setShowNewAssessment(!b)
        setShowAssessment(b)
        const newQuestion = [{
            alternatives: [
                { option: "a", text: "" },
                { option: "b", text: "" },
                { option: "c", text: "" },
                { option: "d", text: "" },
            ],
            ask_question: "",
            correct_answer: "",
            feedback: ""
        }]

        if (!projectAssessment || Object.keys(projectAssessment).length === 0) {
            setProjectAssessment(newQuestion)
        } else {
            setProjectAssessment(prevState => [...prevState, ...newQuestion]);
        }
        setReturnManual(true)
    }

    const answerAssessment = () => {
        setShowPPt(false);
        setShowAssessment(true)
        setHasBtAnAsClicked(true)
    }

    const showDivFloating = (b) => {
        setShowOptions(b)
    }

    const handleReviewContentGenerated = (reviewed, jsonContentReviwed) => {
        if (changeContent) {
            setAnyChange(true)
            setMenuActive(true)
        }

        setContentReviewed(reviewed);
        setProjectContent(jsonContentReviwed)
        setIncludeContent(contentType === "text")
        setIncludeCase(contentType === "case")
    }

    const handleMsgError = (error) => {
        setMsgDialog(error)
        setAction('error');
        setAnyChange(false)
        showDivFloating(true)
    }

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
        switch (lang) {
            case "Portuguese":
                setSubtitleLabel("Português");
                setSrclangSubtitle("pt");
                break;
            case "English":
                setSubtitleLabel("English");
                setSrclangSubtitle("en");
                break;
            case "Spanish":
                setSubtitleLabel("Español");
                setSrclangSubtitle("es");
                break;
            case "French":
                setSubtitleLabel("Français");
                setSrclangSubtitle("fr");
                break
            case "Italian":
                setSubtitleLabel("Italiano");
                setSrclangSubtitle("it");
                break;
            case "Germany":
                setSubtitleLabel("Deutsch");
                setSrclangSubtitle("de");
                break;
            case "Russian":
                setSubtitleLabel("Русский");
                setSrclangSubtitle("ru");
                break;
            case "Mandarin":
                setSubtitleLabel("中文");
                setSrclangSubtitle("zh");
                break;
            case "Japanese":
                setSubtitleLabel("日本語");
                setSrclangSubtitle("ja");
                break;
            case "Korean":
                setSubtitleLabel("한국어");
                setSrclangSubtitle("ko");
                break;
            default:
                setSubtitleLabel("English");
                setSrclangSubtitle("en");
        }
    }

    const convertTextToWebVTT = (subtitles) => {
        const replaceNinthChar = (str) => {
            if (str.length < 9) return str;
            return str.substring(0, 8) + '.0' + str.slice(9);
        }

        let webVTTContent = 'WEBVTT\n\n';
        subtitles.forEach((subtitle, index) => {
            const textWithoutNumber = subtitle.words;
            webVTTContent += `${index + 1}\n`;
            webVTTContent += `${replaceNinthChar(subtitle.startTime)} --> ${replaceNinthChar(subtitle.endTime)}\n`;
            webVTTContent += `${textWithoutNumber}\n\n`;
        });
        return webVTTContent;
    }

    useEffect(() => {
        if (objectSubtitle && objectSubtitle.length > 0) {
            const webVTTSubtitles = convertTextToWebVTT(objectSubtitle);
            if (webVTTSubtitles) {
                setSubTitleFile(webVTTSubtitles);
                const subtitlesBlob = new Blob([webVTTSubtitles], { type: 'text/vtt' });
                const url = URL.createObjectURL(subtitlesBlob);
                setSubTitleUrl(url);
            }
        }
    }, [objectSubtitle]);

    useEffect(() => {
        if (videoSubtitle && videoSubtitle.length > 0) {
            const bttsubtitles = convertTextToWebVTT(videoSubtitle)
            const subtitlesBlob = new Blob([bttsubtitles], { type: 'text/vtt' });
            const url = URL.createObjectURL(subtitlesBlob);
            setSubTitleUrl(url)
        }
    }, [videoSubtitle])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleEditSubtitle = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
        const currentTime = videoRef.current.currentTime;

        // Função para converter o tempo no formato "HH:MM:SS:FF" para segundos
        const parseTime = (timeStr) => {
            const [hours, minutes, seconds, frames] = timeStr.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds + frames / 30; // Assumindo 30 frames por segundo
        };

        // Encontrar o índice do objeto no array objectSubtitle
        const tn = objectSubtitle.findIndex(subtitle => {
            const startTime = parseTime(subtitle.startTime);
            const endTime = parseTime(subtitle.endTime);
            return currentTime >= startTime && currentTime <= endTime;
        });

        setTrackNumber(tn);
        setEditSubtitle(!editSubtitle);
    };

    const changeSubtitleText = (text) => {
        setAnyChange(true);
        setMenuActive(true);
        setObjectSubtitle((prevSubtitles) => {

            const updatedSubtitles = [...prevSubtitles];

            const indexToUpdate = trackNumber;

            if (indexToUpdate >= 0 && indexToUpdate < updatedSubtitles.length) {
                updatedSubtitles[indexToUpdate].words = text;
            }

            return updatedSubtitles;
        });
    };

    const chooseSubtitleLanguage = () => {
        setAction('translate_subtitle');
        setOpenDialog(true);
        setOptionsDialog(['Cancelar', 'Ok']);
    }

    const generateSubtitles = async (option) => {
        setOpenDialog(false)
        setMsgDialog("")
        if (option != "Cancelar" && option != "Cancel") {
            handleAskTranslateGPT();
        }
    }

    const cleanWords = (words) => {
        return words.replace(/["\n\[\]]/g, '').trim();
    };

    const cleanSubtitles = (subtitles) => {
        return subtitles.map(subtitle => ({
            ...subtitle,
            words: cleanWords(subtitle.words)
        }));
    };

    const updateObjectSubtitle = (subtitles, translatedText) => {

        const cleanedTranslatedWords = translatedText
            .replace(/^\n/, '')
            .replace(/\n$/, '')
            .split('\n')
            .map(word => word.trim())
            .filter(word => word !== "");

        if (cleanedTranslatedWords[0] === "[") {
            cleanedTranslatedWords.shift();
        }

        return subtitles.map((subtitle, index) => {
            const translatedWord = cleanedTranslatedWords[index];
            if (translatedWord && translatedWord.trim() !== "") {
                return {
                    ...subtitle,
                    words: translatedWord
                };
            }
            return subtitle;
        });
    };

    const handleAskTranslateGPT = async () => {
        setNewSubtitleLanguage(languageSubtitle)
        setFileTipMessange(t('choosefile:home.traduzindo legendas'))
        setTranslatingSubtitles(true)

        const extractWords = (subtitles) => {
            return subtitles.map(subtitle => subtitle.words);
        };
        const wordsToTranslate = extractWords(objectSubtitle);
        try {
            const res = await handleOpenAiApi({
                objectSubtitle: wordsToTranslate,
                language: languageSubtitle,
                orderGPT: "translateSubtitle",
            });

        } catch (errorGPT) {
            setError(errorGPT);
            setTranslatingSubtitles(false);
        }
    }

    const changeSubtitleLanguage = async (origin, destiny) => {
        setLanguageSubtitle(destiny);
    }

    const closeExportExportProject = (option) => {
        closeMenu()
    }

    const closeVideoInteraction = (option) => {
        setShowVideoInteraction(false)
        setAnyChange(false)
        closeMenu()
        videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - .1);
        videoRef.current.play();
    }

    const addVideoInteraction = () => {
        setShowInteractionButton(!showInteractionButton);
        setVideoOverlayVisible(!videoOverlayVisible);
        if (videoRef.current) {
            videoRef.current.play();
        }
    }

    const addInteractionPoint = () => {
        const formattedTime = formatTime(videoRef.current.currentTime);
        const filteredSubtitles = objectSubtitle
            .filter(subtitle => subtitle.endTime <= formattedTime || (subtitle.startTime <= formattedTime && subtitle.endTime >= formattedTime))
            .map(subtitle => subtitle.words)
            .join(' ');

        setContentUntilInteraction(filteredSubtitles);

        setPointInteraction(formattedTime);
        setCurrentInteractionTime(formattedTime);
        setShowVideoInteraction(!showVideoInteraction)
        setMenuActive(true);
        if (videoRef.current) {
            videoRef.current.pause();
        }
    }

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        const frames = Math.floor((timeInSeconds % 1) * 30);

        const pad = (num, size) => {
            let s = "000" + num;
            return s.substr(s.length - size);
        };

        return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}:${pad(frames, 2)}`;
    };

    const addContent = () => {
        setShowContentParameters(true);
        setMenuActive(true);
    }

    const changeVideoState = (option) => {
        setAnyChange(false)
        closeMenu()
        setShowVideoInteractionObject(option)
        videoRef.current.play()
    }

    const playVideoObjectInteraction = () => {
        if (showVideoInteractionObject) {
            videoRef.current.pause()
        } else {
            videoRef.current.play()
        }
    }

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('play', () => {
                setEditSubtitle(false);
            });
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play', () => {
                    setEditSubtitle(false);
                });
            }
        };
    }, [videoRef.current]);

    useEffect(() => {
        if (project && id != "") {
            setProjectName(project.project_name)
            setShowVideo(project.include_video)
            setShowPPt(project.include_slides)
            setUrlLogo(project.logo_url)
            setListUrlSlides(project.listUrlSlides)
            setProjectLoading(false)
            setShowProgress(project.show_progress)
            setMenuItems(project.menu_items)
            setMenuFreeNavigation(project.menu_free_navigation)
            setTemplateId(project.template_id)
            setOriginalTemplate(project.template_id)
            setProjectAssessment(project.assessment)
            setOriginalQtdQuestions(project.assessment.length)
            setPptAssessmentTogether(project.ppt_assessent_together !== undefined ? project.ppt_assessent_together : false);
            setVideoAssessmentTogether(project.video_assessment_together !== undefined ? project.video_assessment_together : false);
            setPptText(project.ppt_text)
            setVideoUrl(project.video_url)
            setVideoText(project.video_text)
            setVideoPath(project.video_file)
            setIncludeVideo(project.include_video)
            setIncludeSlides(project.include_slides)
            setIncludeContent(project.include_content)
            setIncludeCase(project.include_case)
            setProjectContent(project.content_generated)
            setIAGeneratedQuestions(project.IA_generated_questions || 0)
            setObjectSubtitle(project.subtitle || "")
            setLanguage(project.project_language)
            setOriginalLangSub(project.subtitle_language || "")
            setProjectVideoInteraction(project.video_interaction || [])
        } else if (id === "") {
            setProjectLoading(false)
            project = null
            setProjectName("")
            setDisableItemFloat(true)
            setShowProgress(false)
            setIncludeVideo(false)
            setIncludeSlides(false)
            setListUrlSlides([])
        }
    }, [project])

    useEffect(() => {
        if (projectVideoInteraction) {
            if (projectVideoInteraction.length > 0) {
                setInteractionTimes(projectVideoInteraction.map((interaction) => interaction.interaction_time))
            }
        }
    }, [projectVideoInteraction])

    useEffect(() => {
        if (interactionTimes) {
            interactionTimesInSeconds.current = interactionTimes.map(time => {
                const [hours, minutes, seconds, frames] = time.split(':').map(Number);
                return hours * 3600 + minutes * 60 + seconds + frames / 30;
            });
        }
    }, [interactionTimes]);

    useEffect(() => {
        if (interactionTimes && interactionTimes.length > 0) {
            const handleTimeUpdate = () => {
                if (videoRef.current) {
                    const currentTime = videoRef.current.currentTime;
                    interactionTimesInSeconds.current.forEach(time => {
                        if (Math.abs(currentTime - time) < 0.2 && !triggeredTimes.current.has(time)) {
                            setShowVideoInteractionObject(true);
                            triggeredTimes.current.add(time);
                            setCurrentInteractionTime(time);
                        }
                    });
                }
            };

            const handleVideoSeeked = () => {
                triggeredTimes.current.clear();
            };

            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.addEventListener('timeupdate', handleTimeUpdate);
                videoElement.addEventListener('seeked', handleVideoSeeked);
            }

            return () => {
                if (videoElement) {
                    videoElement.removeEventListener('timeupdate', handleTimeUpdate);
                    videoElement.removeEventListener('seeked', handleVideoSeeked);
                }
            };
        }
    }, [videoRef.current, interactionTimes]);

    useEffect(() => {
        if (showVideoInteractionObject) {
            playVideoObjectInteraction()
        }
    }, [showVideoInteractionObject])

    useEffect(() => {
        if (projectSelected === "assessment" && !reviewProject) {
            setShowNewAssessment(true)
        } else if (projectSelected === "content" && !reviewProject) {
            setShowContentParameters(true)
        }
    }, [projectSelected, reviewProject])

    useEffect(() => {
        if (templates && templateId) {
            templates.map((template) => {
                if (template.id === templateId) {
                    setCssTemplate(template.css)
                }
            })
        }
    }, [templates, templateId])

    useEffect(() => {
        if (pptAssessmentTogether != null || videoAssessmentTogether != null) {
            if (pptAssessmentTogether === true || videoAssessmentTogether === true) {
                setShowAssessment(true)
            } else if (pptAssessmentTogether === false && videoAssessmentTogether === false) {
                setShowAssessment(false)
            }
            setAnyChange(true)
        }

    }, [pptAssessmentTogether, videoAssessmentTogether])

    useEffect(() => {
        if (listUrlSlides) {
            setTotalSlides(listUrlSlides.length)
        }
    }, [listUrlSlides])

    useEffect(() => {
        if (totalSlides && totalSlides !== 0 && slide !== undefined && slide !== null) {
            const progress = ((slide / totalSlides) * 100).toFixed(2);
            setSlidesProgress(parseFloat(progress));
        }
    }, [totalSlides, slide]);

    useEffect(() => {
        if (slide >= visitedSlide - 1) {
            var sl = 0
            if (slide > 0) {
                sl = slide + 1
            } else {
                sl = 0
            }
            const progress = ((sl / totalSlides) * 100).toFixed(2);
            setSlidesProgress(parseFloat(progress));
        }
    }, [slide])

    useEffect(() => {
        if (visitedSlide && visitedSlide === totalSlides && !pptAssessmentTogether && hasAssessment) {
            setShowButtonAnsAss(true);
        }
    }, [visitedSlide])

    useEffect(() => {
        urlLogo && setUploadingFile(false);
    }, [urlLogo])

    useEffect(() => {
        templateId && handleChangeTemplate(templateId)
    }, [templateId])

    useEffect(() => {
        if (menuActive) {
            const divSlides = document.getElementById('divSlides');
            const divCustomBar = document.getElementById('customBar');
            const divAssessment = document.getElementById('projectAssessment')
            if (divSlides && divCustomBar && divAssessment) {
                const heightDivSlides = divSlides.offsetHeight;
                const heightDivCustomBar = divCustomBar.offsetHeight;
                const heightDivAssessment = divAssessment.offsetHeight
                const height = heightDivSlides + heightDivCustomBar;
                setSlidesHeight(height);
            }
        }
    }, [menuActive]);

    useEffect(() => {
        msgDialog && setOpenDialog(true)
    }, [msgDialog])

    useEffect(() => {
        if (showPPt && hasBtAnAsClicked) {
            setSlide(0)
        }
    }, [showPPt])

    useEffect(() => {
        if (projectAssessment && projectAssessment.length > 0) {
            if (projectAssessment[0].ask_question === "" && projectAssessment[0].alternatives[0].text === "") {
                setHasAssessment(false)
            } else {
                setHasAssessment(true)
                setQtdQuestions(projectAssessment.length)
            }
        } else {
            setHasAssessment(false)
            setQtdQuestions(0)
        }

        if (returnIA && assessmentDivRef.current) {
            assessmentDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setReturnIA(false);
        }
    }, [projectAssessment, assessmentDivRef, returnIA]);

    useEffect(() => {
        if (returnManual && assessmentDivRef.current) {
            assessmentDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setReturnManual(false);
        }
    }, [projectAssessment, assessmentDivRef, returnManual])

    useEffect(() => {
        pptText && setProjectContent(pptText)
    }, [pptText])

    useEffect(() => {
        videoText && setProjectContent(videoText)
    }, [videoText])

    useEffect(() => {
        if (templateId) {
            const colorButtonMap = {
                default: "white",
                aqua: "white",
                blue: "black",
                cleanDark: "white",
                cleanLight: "black",
                cleanNeutral: "black",
                coral: "black",
                fresh: "black",
                green: "black",
                pastel: "black",
                purpleRain: "white",
                quizai: "white",
            };
            setTemplateTon(colorButtonMap[templateId]);
        }
    }, [templateId])

    useEffect(() => {
        setNotConsider([])
        if (qtdQuestions > 0) {
            projectAssessment.map((question, index) => {
                setNotConsider(prevNotConsider => [...prevNotConsider, question.ask_question]);
            });
        }
    }, [qtdQuestions, projectAssessment]);

    useEffect(() => {
        projectInfosExport();
    }, [projectAssessment, urlLogo, pptAssessmentTogether, videoAssessmentTogether, cssTemplate, srclangSubtitle, subtitleLabel, projectVideoInteraction])

    useEffect(() => {
        const video = videoRef.current;
        const handleVideoEnded = () => {
            if (!videoAssessmentTogether && projectAssessment && projectAssessment.length > 0) {
                setAnswerAssessmentVisible(true);

            }
            setReviewVideoVisible(true);
            setVideoOverlayVisible(true);
        };
        if (video) {
            video.addEventListener('ended', handleVideoEnded);
            return () => {
                video.removeEventListener('ended', handleVideoEnded);
            };
        }
    }, [videoRef.current, videoAssessmentTogether]);

    useEffect(() => {
        if (qtdQuestions > 0 && !includeVideo && !includeSlides) {
            setShowAssessment(true)
        }
    }, [qtdQuestions, includeVideo, includeSlides])

    useEffect(() => {
        includeCase && setContentType("case")
    }, [includeCase])

    useEffect(() => {
        includeContent && setContentType("text")
    }, [includeContent])

    useEffect(() => {
        if (language) {
            handleChangeLanguage(language)
        }
    }, [language])

    useEffect(() => {
        if (translatingSubtitles) {
            setAction("askingIA")
            setOpenDialog(true)
            setMsgDialog(t('projectpreview:messages.traduzindo legenda'))
        }
    }, [translatingSubtitles])

    useEffect(() => {
        if (returnGPT) {
            //setObjectSubtitle(returnGPT)
            const testelegendas = updateObjectSubtitle(objectSubtitle, returnGPT)
            const cleanedLegendas = cleanSubtitles(testelegendas);
            setObjectSubtitle(cleanedLegendas)
            setOpenDialog(false)
            setMsgDialog("")
            setSubtitleTranslated(true)
            setTranslatingSubtitles(false)
        }
    }, [returnGPT])

    useEffect(() => {
        if (errorGPT) {
            setAction("error")
            setMsgDialog(errorGPT)
        }
    }, [errorGPT])

    useEffect(() => {
        if (subtitleTranslated) {
            setSubtitleTranslated(false)
            updateProject()
        }
    }, [subtitleTranslated])

    useEffect(() => {
        const showMessageAgain = localStorage.getItem('showMessageAgain') === 'true';
        if (showMessageAgain && (includeVideo || includeSlides)) {
            const textVideo = preferenceLanguage === "Inglês" ? `video` : `vídeo`;
            const msg = t('projectpreview:messages.seu projeto convertido', {
                projectType: includeVideo ? textVideo : `powerpoint`,
                interpolation: { prefix: '${', suffix: '}' }
            }) + (includeVideo ? t('projectpreview:messages.revise as legendas') : '');
            setMsgDialog(msg);
            setOpenDialog(true);
            setAction("infos_project");
        }
    }, [includeVideo, includeSlides]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={` ${chooseTemplate.body_project} `}>
            {projectLoading
                ? <div className="d-flex justify-content-center mt-5">
                    <div className="align-self-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                </div>
                : <div className='d-flex justify-content-center' >

                    {showVideoInteraction &&
                        <div className={`col-3 ${styles.div_projectExport}`} style={{ height: slidesHeight }}>
                            <VideoInteraction
                                originalLanguage={language}
                                contentUntilInteraction={contentUntilInteraction}
                                pointInteraction={pointInteraction}
                                userId={userId}
                                projectId={idProject}
                                closeVideoInteraction={closeVideoInteraction}
                                projectVideoInteraction={projectVideoInteraction}
                                editProject={editProject}
                            />
                        </div>}

                    {showTemplates &&
                        <div className={`col-2 ${styles.div_templates}`} style={{ height: slidesHeight }}>
                            <ProjectTemplate
                                handleChangeTemplate={handleChangeTemplate}
                                actualTemplate={templateId}
                            />
                        </div>}

                    {(showNewAssessment || showAssessmentParameters || generateQuestions) &&
                        <div className={`col-3  ${styles.div_templates}`} style={{ height: slidesHeight }}>
                            <AssessmentParameters
                                idProject={idProject}
                                projectContent={contentReviewed ? contentReviewed : projectContent}
                                assessmentIAGenerated={assessmentIAGenerated}
                                showDivFloating={showDivFloating}
                                qtdQuestions={qtdQuestions}
                                notConsider={notConsider}
                                changeIdProject={changeIdProject}
                                projectSelected={projectSelected}
                                actionMsgDialog={actionMsgDialog}
                                addFileManualQuestion={addFileManualQuestion}
                                generateQuestions={generateQuestions}
                                pn={projectName}
                                handleMsgError={handleMsgError}
                                IAGeneratedQuestions={IAGeneratedQuestions}
                                objectSubtitle={objectSubtitle}
                                translateIAGenerated={translateIAGenerated}
                                handleChangeLanguage={handleChangeLanguage}
                                originalLanguage={language}
                            />
                        </div>}

                    {(showExportProject && projectInfos) &&
                        <div className={`col-3 ${styles.div_projectExport}`} style={{ height: slidesHeight }}>
                            <ProjectExport
                                projectInfos={projectInfos}
                                handleMsgError={handleMsgError}
                                closeExportExportProject={closeExportExportProject}
                            />
                        </div >}

                    {showContentParameters &&
                        <div className={`col-3 ${styles.div_projectExport}`} style={{ height: slidesHeight }}>
                            <ContentParameters
                                changeIdProject={changeIdProject}
                                handleMsgError={handleMsgError}
                            />
                        </div>
                    }

                    <div className={`${showNewAssessment || showAssessmentParameters || showExportProject || generateQuestions || showContentParameters || showVideoInteraction ? `col-9` : showTemplates ? `col-10` : `col-11`} `} style={(showNewAssessment || showAssessmentParameters || showExportProject || generateQuestions || showTemplates ? { height: slidesHeight, overflow: 'scroll' } : {})}>
                        <div className={` ${chooseTemplate.custom_bar} ${chooseTemplate.top_bar} ${(includeVideo || includeSlides) ? chooseTemplate.adjust_top_bottom : ``} d-flex  justify-content-around `} id='customBar'>
                            {uploadingFile
                                ? <div className={`col-1`}>
                                    <div className={`progress ${styles.progressContainer}`} role="progressbar" aria-label="Info striped example" aria-valuenow={progresspercent} aria-valuemin="0" aria-valuemax="100">
                                        <div className={`progress-bar progress-bar-striped bg-info`} style={{ width: `${progresspercent}%` }}></div>
                                    </div>
                                </div>
                                : urlLogo
                                    ? <div className={`${chooseTemplate.logo} d-flex align-items-center`} >
                                        <img className={`img-fluid p-1  ${editProject ? styles.edit_logo : ``} `} src={urlLogo} id="div_logo" onClick={editProject ? () => handleIncludeLogo() : null} />
                                        {editProject
                                            && <div className={`d-flex align-items-center`}>
                                                <i onClick={() => handleDeleteLogo()} onMouseEnter={() => setShowSubDeleteLogo(true)} onMouseLeave={() => setShowSubDeleteLogo(false)} className={`bi bi-x-circle ms-2 ${styles.icon_delete_logo}`}></i>
                                                {showSubDeleteLogo &&
                                                    <div className={` ${styles.text_delete_logo}`} > {t('projectpreview:home.Apagar logo')} </div>}
                                            </div>}
                                    </div>
                                    : editProject &&
                                    <div className={`d-flex col-auto ${styles.div_logo}`}>
                                        <i className={`bi bi-plus-square-dotted col-auto ${styles.icon_logo}`} onClick={() => handleIncludeLogo()} onMouseLeave={() => setShowSubLogo(false)} onMouseEnter={() => setShowSubLogo(true)}></i>
                                        <div className={`ms-5 mt-2 align-items-center ${styles.subtitles} ${showSubLogo ? 'd-flex' : 'd-none'}`}>{t('projectpreview:home.Incluir logo')}</div>
                                    </div>
                            }
                            <div className={`${chooseTemplate.course_title} ${(urlLogo || editProject) ? chooseTemplate.adjust_align : styles.adjust_title}`}>
                                <input type="text" id="title" name="title" value={projectName} className={`col-9 ${styles.input_title} ${urlLogo ? 'text-center' : 'text-start'}`} onChange={(e) => changeProjectName(e.target.value)} disabled={!editProject} />
                            </div>

                            <div className={`${styles.progressBarWidth}`}>
                                {showProgress
                                    ? <div className={`align-items-center row ${styles.div_progressBar} justify-content-end me-3`}>
                                        {editProject && <div className={`d-flex col-2 col-md-3 align-items-center justify-content-center`}>
                                            <i onClick={() => changeShowProgress()} onMouseEnter={() => setShowSubProgressBar(true)} onMouseLeave={() => setShowSubProgressBar(false)} className={`bi bi-x-circle ${styles.icon_delete_logo}`}></i>
                                            {showSubProgressBar &&
                                                <div className={` ${styles.text_disable_pb}`} > {t('projectpreview:home.Desabilitar barra de progressos')} </div>}
                                        </div>}
                                        <div className={`${chooseTemplate.progressBarContainer} p-0 col-10 col-md-9`} id="progressBarContainer">
                                            <div className={`${chooseTemplate.progressBar} ${slidesProgress === 100 ? chooseTemplate.progressBarEnd : ''}`} id="progressBar" style={{ width: `${slidesProgress}%`, marginLeft: '0' }}></div>
                                            <div className={`${chooseTemplate.progressTextContainer} `} id="progressTextContainer">{slidesProgress}%</div>
                                        </div>
                                    </div>
                                    : editProject && showPPt
                                    && <div className={`d-flex align-items-center justify-content-end me-3`}>
                                        <i onClick={() => changeShowProgress()} onMouseEnter={() => setShowSubProgressBar(true)} onMouseLeave={() => setShowSubProgressBar(false)} className={`bi bi-plus-circle  ${styles.icon_delete_logo}`}></i>
                                        {showSubProgressBar &&
                                            <div className={` ${styles.text_able_pb}`}> {t('projectpreview:home.Habilitar barra de progressos')} </div>}
                                    </div>}
                            </div>
                        </div>

                        <div className={`${idProject ? `` : styles.heightDivSlides}`} id="divSlides">
                            {showVideo && (
                                <div>
                                    <div className={`container p-0 m-0 ${styles.videoContainer}`}>
                                        <div className={(videoOverlayVisible && !editProject) ? styles.videoOverlay : ''}></div>

                                        <div className={`${styles.video_container}`}>
                                            <video controls width="80%" height="80%" id="myVideo" ref={videoRef}>
                                                <source src={videoUrl} type="video/mp4" />
                                                {!editSubtitle &&
                                                    <track
                                                        className={`${styles.trackContainer}`}
                                                        src={subTitleUrl}
                                                        kind="subtitles"
                                                        srclang={srclangSubtitle}
                                                        label={subtitleLabel}
                                                        default
                                                    />}
                                            </video>
                                        </div>
                                        {showVideoInteractionObject &&
                                            <div>
                                                <VideoInteractionObject
                                                    changeVideoState={changeVideoState}
                                                    currentInteractionTime={currentInteractionTime}
                                                    projectVideoInteraction={projectVideoInteraction}
                                                    changeVideoInteraction={changeVideoInteraction}
                                                    editProject={editProject}
                                                    language={language}
                                                />
                                            </div>}
                                        {editSubtitle &&
                                            <div>
                                                <textarea
                                                    name='subtitles'
                                                    className={`${styles.subtitleContainer}`}
                                                    rows={3}
                                                    value={objectSubtitle[trackNumber]?.words || ''}
                                                    onChange={(e) => changeSubtitleText(e.target.value)} />
                                            </div>}
                                        <div className={`row position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center`}>
                                            {(reviewVideoVisible && !editProject) && (
                                                <div className="col-6">
                                                    <button
                                                        className="w-100 mt-3 btn btn-primary"
                                                        id={`${chooseTemplate.reviewVideo}`}
                                                        style={{ fontSize: '20px', borderRadius: '5em', minWidth: '10em', maxWidth: '10em' }}
                                                        onClick={() => handleReviewVideo()}
                                                    >
                                                        {t('projectpreview:home.Rever')} <br /> {t('projectpreview:home.Video')}
                                                    </button>
                                                </div>
                                            )}
                                            {(answerAssessmentVisible && !editProject) && (
                                                <div className="col-6">
                                                    <button
                                                        className="w-25 mt-3 btn btn-primary"
                                                        id={`${chooseTemplate.answerAssessment}`}
                                                        style={{ fontSize: '20px', borderRadius: '5em', minWidth: '10em', maxWidth: '10em' }}
                                                        onClick={() => handleAnswerAssessment()}
                                                    >
                                                        {t('projectpreview:home.Responder avaliação')}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showInteractionButton &&
                                            <div className={`${styles.interactionDiv}`} onClick={() => addInteractionPoint()}>
                                                <i className={`bi bi-node-plus-fill ${styles.interactionIcon}`}></i>
                                            </div>}
                                    </div>
                                </div>
                            )}

                            {showPPt && !showVideo &&
                                <div className={``} >
                                    <div className={`carousel slide `} ref={carouselRef} id="carouselSlides">
                                        <div className={`carousel-inner `}>
                                            {listUrlSlides && listUrlSlides.map((slide, index) => {
                                                return (
                                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                                        <img src={slide} className={`d-block  ${styles.adjust_slide}`} alt={index} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <button className="carousel-control-prev d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="prev">
                                            <span className="prevButton carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next d-none" type="button" data-bs-target="#carouselSlides" data-bs-slide="next" >
                                            <span className="nextButton carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                    <div className={`${chooseTemplate.custom_bar} ${chooseTemplate.bottom_bar} ${styles.adjust_bottom} ${(includeVideo || includeSlides) ? chooseTemplate.adjust_top_bottom : ``}`}>
                                        <div className="col-auto btn-group dropup">
                                            <button type="button" className="btn btn-secondary dropdown-toggle menu_button" id={`${styles.menuButton}`} data-bs-toggle="dropdown" >
                                                <i className={`bi bi-list ${chooseTemplate.menu_icon}`}></i>
                                            </button>
                                            <ul className={`dropdown-menu ms-1 ${editProject ? "show" : ""} ${editProject ? styles.menu_edit : ""}`} id={`${styles.slideMenu}`}>
                                                {menuItems && menuItems.map((item, index) => {
                                                    return (
                                                        <div key={index} className={`${styles.menu_items} ${(!menuFreeNavigation && index >= visitedSlide) ? styles.disabled_menu_item : ''}`} onClick={() => !editProject && changeMenuPage(index)}>
                                                            <li className={`ms-2 mt-1 ${styles.dropdown_item}`} data-bs-target="#carouselSlides" data-bs-slide-to={index}>
                                                                {editProject
                                                                    ? <input type="text" id="menu" name="menu" value={item} className={`${styles.input_menu}`} onChange={(e) => changeMenuItemName(index, e.target.value)} />
                                                                    : <span>{item}</span>
                                                                }
                                                            </li>
                                                        </div>
                                                    );
                                                })}
                                                {editProject && !showVideo && <div>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li>
                                                        <div className="form-check ms-2">
                                                            <input className="form-check-input" type="checkbox" value={menuFreeNavigation} checked={menuFreeNavigation} id="flexCheckDefault" onChange={() => changeMenuFreeNavigation()} />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                {t('projectpreview:home.Navegação livre')}
                                                            </label>
                                                        </div>
                                                    </li>
                                                </div>}
                                            </ul>
                                        </div>
                                        <div className={`col-auto ${chooseTemplate.menu_adjust} `}>
                                            {(showButtonAnsAss && !hasBtAnAsClicked && !editProject) && <button onClick={() => answerAssessment()} className={`${styles.answer_assessment} ${chooseTemplate.background_button}`}>{t('projectpreview:home.Responder avaliação')}</button>}
                                            <button onClick={() => prevButtonCarousel()} className={`${chooseTemplate.button_navigation}`} id="prevButtonCarousel" disabled={slide === 0}><i className={`bi bi-arrow-left-circle ${styles.icons_menu}`}></i></button>
                                            <button onClick={() => nextButtonCarousel()} className={`${chooseTemplate.button_navigation}`} id="nextButtonCarousel" disabled={slide === totalSlides - 1}><i className={`bi bi-arrow-right-circle ${styles.icons_menu}`}></i></button>
                                        </div>
                                    </div>

                                </div>
                            }
                            {editProject && (includeSlides || includeVideo) &&
                                <div className="mt-5 ms-3 form-check form-switch">
                                    <div className={`form-check-label ${templateTon === "white" ? styles.text_showtowgether_white : styles.text_showtowgether_black}`}>
                                        {t('projectpreview:home.apresentar em conjunto', {
                                            projectType: showVideo ? `${t('projectpreview:home.video')}` : `${t('projectpreview:home.slides')}`,
                                            interpolation: { prefix: '${', suffix: '}' }
                                        })}

                                        <input
                                            disabled={!editProject}
                                            className={`form-check-input ${chooseTemplate.option_check} `}
                                            id="flex-switch"
                                            type="checkbox"
                                            checked={showVideo ? videoAssessmentTogether : pptAssessmentTogether}
                                            onChange={(e) => {
                                                showVideo
                                                    ? setVideoAssessmentTogether(e.target.checked)
                                                    : setPptAssessmentTogether(e.target.checked)
                                            }}
                                        />
                                    </div>
                                </div>}
                            <p></p>
                            <div id='scroolAssessment'></div>

                            {(projectContent && contentType === "text") &&
                                <div className="mb-1">
                                    <AssessmentContent
                                        contentGenerated={projectContent}
                                        editProject={editProject}
                                        template={chooseTemplate}
                                        language={language}
                                        handleReviewContentGenerated={handleReviewContentGenerated}
                                        handleChangeContent={handleChangeContent}
                                        infoText={`Revise seu texto abaixo:`}
                                        changeProjectName={changeProjectName} />
                                </div>
                            }

                            {(projectContent && contentType === "case") &&
                                <div className="mb-1">
                                    <AssessmentCase
                                        contentGenerated={projectContent}
                                        editProject={editProject}
                                        template={chooseTemplate}
                                        language={language}
                                        handleReviewContentGenerated={handleReviewContentGenerated}
                                        handleChangeContent={handleChangeContent}
                                        infoText={`Revise seu case abaixo:`}
                                        changeProjectName={changeProjectName}
                                    />
                                </div>
                            }

                            {(projectAssessment && projectAssessment.length > 0 && showAssessment) &&
                                <div className={`d-flex align-items-center justify-content-center flex-column `} >
                                    <AssessmentQuestions
                                        projectAssessment={projectAssessment}
                                        editProject={editProject}
                                        template={chooseTemplate}
                                        templateId={templateId}
                                        changeAssessment={changeAssessment}
                                        originalQtdQuestions={originalQtdQuestions}
                                        assessmentDivRef={assessmentDivRef}
                                    />
                                </div>}
                            <div id='scroolEndAssessment'></div>
                            {<div className={`${styles.floatingDiv}  ${showOptions ? '' : 'd-none'}`}>
                                {menuActive
                                    ? anyChange
                                        ?
                                        <div>
                                            <button className={`btn btn-secondary`} type="button" id={`${styles.button_option}`} onClick={() => closeMenuChange()}>
                                                <i className={`bi bi-check-circle-fill d-flex row justify-content-end ${styles.icon_options} ${chooseTemplate.background_floating}`} ></i>
                                            </button>
                                        </div>
                                        :
                                        <div>
                                            <button className={`btn btn-secondary`} type="button" id={`${styles.button_option}`} onClick={() => closeMenu()}>
                                                <i className={`bi bi-x-circle-fill d-flex row justify-content-end ${styles.icon_options} ${chooseTemplate.background_floating}`} ></i>
                                            </button>
                                        </div>
                                    : <Dropdown
                                        show={dropdownOpen}
                                        onToggle={handleDropdownToggle}
                                        className={dropdownOpen ? styles.dropdown_active : styles.dropdown_inactive}
                                    >
                                        <Dropdown.Toggle variant="secondary" className={`button_option ${styles.adjust_buttonOption}`} id={`${styles.button_option}`}>
                                            <i className={`bi bi-plus-circle-fill d-flex row justify-content-end ${styles.icon_options} ${chooseTemplate.background_floating}`} ></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={dropdownOpen ? styles.dropdown_active : styles.dropdown_inactive}>
                                            <Dropdown.Item href="#" onClick={() => changeProject()} disabled={disableItemFloat} >{t('projectpreview:menu.Editar projeto')}</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => changeTemplate()} disabled={disableItemFloat}>{t('projectpreview:menu.Alterar template')}</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => addQuizz()} disabled={qtdQuestions >= 20 || disableItemFloat}>{qtdQuestions > 0 ? `${t('projectpreview:menu.Adicionar Questão')}` : `${t('projectpreview:menu.Adicionar Quiz')}`}</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => exportProject()} disabled={disableItemFloat}>{t('projectpreview:menu.Exportar projeto')}</Dropdown.Item>
                                            {includeVideo && <Dropdown.Item href="#" onClick={() => handleEditSubtitle()} disabled={disableItemFloat}>{t('projectpreview:menu.Editar Legenda')}</Dropdown.Item>}
                                            {includeVideo && <Dropdown.Item href="#" onClick={() => chooseSubtitleLanguage()} disabled={disableItemFloat}>{t('projectpreview:menu.Traduzir Legenda')}</Dropdown.Item>}
                                            {includeVideo && <Dropdown.Item href="#" onClick={() => addInteractionPoint()} disabled={disableItemFloat}>{t('projectpreview:menu.Adicionar interação')}</Dropdown.Item>}
                                            {/* includeSlides && <Dropdown.Item href="#" onClick={() => addContent()} disabled={disableItemFloat}>{t('projectpreview:menu.Adicionar conteúdo')}</Dropdown.Item> */}
                                            <Dropdown.Item href="#" onClick={() => handleReturnHome()}>{t('projectpreview:menu.Retornar')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>}
                            </div>}
                        </div>
                    </div>
                </div>
            }
            <div>
                {openDialog && <DialogBox
                    isopen={true}
                    questionDialog={msgDialog}
                    optionsDialog={optionsDialog}
                    action={action}
                    actionScript={action === 'translate_subtitle' ? generateSubtitles : actionScript}
                    changeSubtitleLanguage={changeSubtitleLanguage}
                    langSubtitle={originalLagSub}
                />}
            </div>
        </div>
    )
}

export default ProjectPreview