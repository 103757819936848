import styles from './DialogBoxResend.module.css'
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import scormAiLogo from '../images/logo-ScormAI.png';

function DialogBoxResend({ actionSendEmail, handleClosDialogBoxResend, questionDialog, isopen, userSetLanguage }) {

    const [email, setEmail] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(isopen);
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");

    const [userLanguage, setUserLanguage ] = useState(userSetLanguage ? userSetLanguage : navigator.language);

    let actionChange;

    const handleCloseModal = (e) => {
        e.preventDefault();
        setModalIsOpen(false);
        actionSendEmail(email);
    };

    const handleCancelModal = () => {
        setModalIsOpen(false);
        handleClosDialogBoxResend()
    }

    const handleClickSend = () => {
        const sendButton = document.querySelector('.sendEmail').click();
    }

    useEffect(() => {
        if(userSetLanguage){
          setUserLanguage(userSetLanguage)
        }
      }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
          case "pt-BR":
          case "pt":
            setPreferenceBrowserLanguage("Português");
            break;
          case "en-US":
          case "en":
            setPreferenceBrowserLanguage("Inglês");
            break;
          case "es-ES":
          case "es":
            setPreferenceBrowserLanguage("Espanhol");
            break;
          default:
            setPreferenceBrowserLanguage("Inglês");
            break;
        }
      }, [userLanguage]);

    return (
        <div>
            {
                <div>
                    <Modal show={modalIsOpen} backdrop="static" centered>
                        <Modal.Header >
                            <img src={scormAiLogo} alt="QuizAI" className={styles.logo} />
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div>
                                    {questionDialog}
                                </div>
                                <div>
                                    <form onSubmit={handleCloseModal}>
                                        <label>
                                            <p></p>
                                            <input
                                                className={`${styles.form_email}`}
                                                type="email"
                                                required
                                                name="email"
                                               
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </label>
                                        <button className="d-none sendEmail"></button>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button className={`${styles.btn_cancel} me-3`} value={actionChange} onClick={() => handleCancelModal()} >Cancel</button>
                                <button className={`${styles.btn_style}`} value={actionChange} onClick={() => handleClickSend()} >Ok</button>
                            </div>
                        </Modal.Footer>

                    </Modal>

                </div>}
        </div>
    );
}

export default DialogBoxResend