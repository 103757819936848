import { db } from '../firebase/config';
import { useInsertDocument } from './useInsertDocument';
import { Timestamp } from 'firebase/firestore';


export const useCreateUserDoc= () => {
    let userID = "";

    const { insertDocument, response } = useInsertDocument("Users", userID);

    const createUserDoc = async (userInfos) => {

        userID = userInfos.userID;

        const newUser = {
            audience: true,
            company: userInfos.company,
            country: userInfos.country,
            domain: userID,
            email: userInfos.email,
            first_access: true,
            job_title: userInfos.jobTitle,
            name: userInfos.displayName,
            language: userInfos.language,
            signature_date:  Timestamp.now(),
            user_active: true,
            profile: userInfos.profile,
        }

        await insertDocument(newUser);
    
    }

    return {createUserDoc};

}
