import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import styles from './UsercontentCarousel.module.css';
import { useState, useEffect, useRef } from 'react';
import { useLanguageValue } from '../context/LanguageContext';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import MenuCourse from '../components/MenuCourse';
import Carousel from 'react-bootstrap/Carousel';

const StartCarousel = ({ userContent }) => {

    const [page, setPage] = useState(0);
    const [slide, setSlide] = useState(1);
    const [qtdSlides, setQtdSlides] = useState(8);
    const [visitedSlides, setVisitedSlides] = useState(1)
    const [titleContent, setTitleContent] = useState([]);
    const [slideContent, setSlideContent] = useState({
        title: '',
        intro: '',
        topics: [],
        conclusion: '',
    });
    const [listUrlSlides, setListUrlSlides] = useState([])
    const [loadingSlides, setLoadingSlides] = useState(true)
    const [progress, setProgress] = useState(0);
    const [slidePage, setSlidePage] = useState(1);
    const [buttonLeftDisabled, setButtonLeftDisabled] = useState(true);
    const [buttonRightDisabled, setButtonRightDisabled] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [menuIntens, setMenuItens] = useState([]);

    const { preferenceLanguage } = useLanguageValue();

    const { updateDocument, responseUpdate } = useUpdateDocument("Users");

    const percentCompleted = (visitedSlides / qtdSlides) * 100;

    const nextCarousel = () => {
        if (slide < 8) {
            setSlide(slide + 1)
            slide >= visitedSlides && setVisitedSlides(visitedSlides + 1)
        } else {
            setSlide(1)
        }
        setButtonsDisabled(true)
        const prevButton = document.querySelector('.introNext').click();
        setTimeout(() => {
            setButtonsDisabled(false);
        }, 700);
    }

    const prevCarousel = () => {
        if (slide > 1) {
            setSlide(slide - 1)
        } else {
            setSlide(8)
        }
        setButtonsDisabled(true)
        const prevButton = document.querySelector('.introPrev').click();
        setTimeout(() => {
            setButtonsDisabled(false);
        }, 700);
    }

   const handleChangeDropdown = (page) => {
    console.log("Page:" + page)
    setPage(page);
   }

    useEffect(() => {
        if (slide === 8) {
            setButtonRightDisabled(true)
        } else {
            setButtonRightDisabled(false)
        }

        if (slide === 1) {
            setButtonLeftDisabled(true)
        } else {
            setButtonLeftDisabled(false)
        }
    }, [slide])

    useEffect(() => {
        if (userContent) {
            setSlideContent(userContent)
        }
    }, [userContent]);

    useEffect(() => {
        if (slideContent) {
            setLoadingSlides(false)
        }
    }, [slideContent])

    useEffect(() => {
        const processJson = (data) => {
            const resultArray = [];
            if (data.intro) {
                resultArray.push('Introdução');
            }
            if (data.topics && Array.isArray(data.topics)) {
                data.topics.forEach((topic) => {
                    if (topic.subtitle) {
                        resultArray.push(topic.subtitle);
                    }
                });
            }
            if (data.conclusion) {
                resultArray.push('Conclusão');
            }
            console.log(resultArray);
            setMenuItens(resultArray);
        };
        slideContent && processJson(slideContent);
    }, [slideContent]);

    const carouselRef = useRef(null);

    useEffect(() => {
      if (carouselRef.current) {
        // Pular para o slide desejado
        //carouselRef.current.to(slide);
      }
    }, [slide]);

    return (
        <div className={`col-12 `}>
            <div className={`${styles.custom_bar} ${styles.top_bar}`}>
                <h4 className={`${styles.course_title}`}>{slideContent.title && (slideContent.title)}</h4>
                <div className={styles.progressBarContainer}>
                    <div className={`${styles.progressBar} ${percentCompleted === 100 && styles.progressBarEnd}`} style={{ width: `${percentCompleted}%` }}></div>
                    <div className={styles.progressTextContainer}>
                        {`${percentCompleted.toFixed(0)}%`}
                    </div>
                </div>
            </div>

            <div className={`${styles.slide_customization}`}>
                <div className={`row d-flex align-items-center justify-content-center`}>
                    <div className={`col-11`}>
                        <div id="startCarousel" className={`carousel slide `} ref={carouselRef}>
                            <div className={`carousel-inner ${styles.carousel_inner}`}>
                                <div className="carousel-item active" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            <h5 className={`${styles.carousel_texts}`}>{slideContent.title && (slideContent.title)}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item ">
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            <h5 className={`${styles.carousel_texts}`}>{slideContent.intro && (slideContent.intro)}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            {slideContent.topics && slideContent.topics[0] && (
                                                <h5 className={`${styles.carousel_texts}`}>{slideContent.topics[0].content}</h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            {slideContent.topics && slideContent.topics[1] && (
                                                <h5 className={`${styles.carousel_texts}`}>{slideContent.topics[1].content}</h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            {slideContent.topics && slideContent.topics[2] && (
                                                <h5 className={`${styles.carousel_texts}`}>{slideContent.topics[2].content}</h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            {slideContent.topics && slideContent.topics[3] && (
                                                <h5 className={`${styles.carousel_texts}`}>{slideContent.topics[3].content}</h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item" >
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            {slideContent.topics && slideContent.topics[4] && (
                                                <h5 className={`${styles.carousel_texts}`}>{slideContent.topics[4].content}</h5>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="text-center">
                                        <div className={`${styles.caption}`}>
                                            <h5 className={`${styles.carousel_texts}`}>{slideContent.conclusion && (slideContent.conclusion)}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className={`introPrev carousel-control-prev d-none`} type="button" data-bs-target="#startCarousel" data-bs-slide="prev">
                                <span className={`carousel-control-prev-icon ${styles.carousel_prev}`} aria-hidden="true"></span>
                            </button>
                            <button className={`introNext carousel-control-next d-none`} type="button" data-bs-target="#startCarousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.custom_bar} ${styles.bottom_bar}`}>
                <div>

                    <div className="col-auto">
                        <MenuCourse
                            handleChangeDropdown={handleChangeDropdown}
                            menuProject={false}
                            items={menuIntens}
                            visitedSlides={visitedSlides}
                        />
                    </div>
                </div>
                <div className={`col-auto ${styles.menu_adjust}`}>
                    <button className={`${styles.button_navigation}`} onClick={() => prevCarousel()} disabled={buttonsDisabled || buttonLeftDisabled}><i className="bi bi-arrow-left-circle"></i></button>
                    <button className={`${styles.button_navigation}`} onClick={() => nextCarousel()} disabled={buttonsDisabled || buttonRightDisabled}><i className="bi bi-arrow-right-circle"></i></button>
                </div>
            </div>
        </div>
    )
}

export default StartCarousel;