import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import styles from './DataProcessingAgreement.module.css'

/* --- IMAGENS --- */
import arrowLegal from '../../images/arrowLegal.svg';

const DataProcessingAgreement = () => {

	/* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
	const fadeInElements = useRef([]);

	const navigate = useNavigate();

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.2,
		};

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(styles.fadeIn);
					observer.unobserve(entry.target);
				}
			});
		}, options);

		fadeInElements.current.forEach(element => {
			observer.observe(element);
		});

		return () => {
			observer.disconnect();
		};
	}, []);
	/* --- FIM DA ANIMAÇÃO POR SCROLL --- */

	useEffect(() => {
		const indicatorsElements = document.querySelectorAll('.carousel-indicators');
		if (indicatorsElements) {
			indicatorsElements.forEach(element => {
				element.classList.add(styles.seletores);
			});
		}
	}, []);

	/* --- INICIO --- adicionar para retirar o padding do container parent da página */
	useEffect(() => {
		const capsula = document.querySelector('.container');
		if (capsula) {
			capsula.classList.add('p-0');
		}
	}, []);
	/* --- FIM --- adicionar para retirar o padding do container parent da página */

	/* --- INICIO SCROLL TOP --- */
	const [showScrollButton, setShowScrollButton] = useState(false);
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	const handleScroll = () => {
		if (window.pageYOffset > 100) {
			setShowScrollButton(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollButton(false);
			$('.scrollToTop').hide();
		}

		if (window.pageYOffset > 30) {
			setShowScrollToTop(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollToTop(false);
			$('.scrollToTop').hide();
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	/* --- FIM SCROLL TOP --- */

	const goGdpr = () => {
		navigate("/gdpr")
	}

	const goPrivacy = () => {
		navigate("/privacy")
	}

	const goTerms = () => {
		navigate("/terms")
	}


	return (
		<div className={`${styles.bg} ${styles.text_left}`}>
			<section className={`${styles.secao} ${styles.call_to_action} vertical-center fade-in text-center text-md-left`}>
				<div className={`${styles.overlay}`}></div>
				<div className={`${styles.area}  text-center ${styles.text_md_left}`}>
					<div className={`row`}>
						<div className={`col-sm-12 col-md-6`}>
							<h1>Data Protection | DPA Notice - ScormAI</h1>
						</div>
					</div>
				</div>
			</section>
			<section id="data-contrato" className={`${styles.area} ${styles.contrato} ${styles.legais} text-left`}>
				<div id="parte1" className={`row h-100 justify-content-center align-items-center`}>
					<div className={`col-12 col-md-11`}>
						<h1>Data Protection | DPA Notice - ScormAI</h1>
						<p>
							This document lays out the responsibilities of Clarity Solutions (Brazilian company), hereafter referred to as ScormAI (Software as a Service), to its customers with regards to data protection in general and the European Union's General Data Protection Regulation (GDPR) specifically.
						</p>

						<ol>
							<li>
								<h3>ScormAI as Data Processor, Definitions</h3>
								<ul>
									<li>
										ScormAI is a Data Processor operating on behalf of its customers.
									</li>
									<li>
										Customers are individuals or organizations paying money to use the ScormAI service. Free trial users of the ScormAI Service are not Customers and should not send ScormAI personal financial data.
									</li>
									<li>
										ScormAI's Customers are Data Controllers.
									</li>
									<li>
										“Personal data” means any information relating to an identified or identifiable person.
									</li>
									<li>
										“ Protection Laws” means EU Directive 95/46/EC, as transposed into domestic legislation of each Member State and as amended, replaced or superseded from time to time, including by the GDPR and laws implementing or supplementing the GDPR.
									</li>
									<li>
										"Services" means the ScormAI website (application program interface) and the professional services provided by ScormAI.
									</li>
									<li>
										“Sub-processor” means any Data Processor engaged by ScormAI.
									</li>
									<li>
										“Data Subject” means the individual to whom Personal Data relates.
									</li>
								</ul>
							</li>
							<li>
								<h3>Processing of Personal Data</h3>
								<ul>
									<li>
										Use of the service implies that ScormAI may process personal data on behalf of the Data Controller in accordance with the requirements of Data Protection Laws. The Data Controller will ensure that instructions to ScormAI for the processing of personal data comply with Data Protection Laws. The Data Controller is solely responsibility for the accuracy, quality, and legality of Personal Data and the means by which it acquires personal data.
									</li>
									<li>
										The inputs to the ScormAI Service provided by the Data Controller are URLs and optional parameters. No other data should be sent to ScormAI. The Data Controller bears sole responsibility for transmission of URls that can include personal data.
									</li>
									<li>
										ScormAI lays out a full and accurate description of its data protection practices on its website <a href="gdpr.html">(GDPR section)</a>. This description is updated from time to time as and when practices change.
									</li>
								</ul>
							</li>
							<li>
								<h3>Rights of Data Subjects</h3>
								<ul>
									<li>
										The Data Controller is solely responsible for the collecting of all necessary consent from Data Subjects to allow ScormAI to process personal data on its behalf.
									</li>
									<li>
										ScormAI will, to the extent legally permitted, promptly notify the Data Controller if it receives a request from a Data Subject for access to, or deletion of, that person's personal data. ScormAI will not respond to a Data Subject request without the Data Controllers prior written consent except to confirm that the request relates to the Data Controller. The Data Controller is solely responsible for completing such request as required by law.
									</li>
								</ul>
							</li>
							<li>
								<h3>Personnel</h3>
								<ul>
									<li>
										ScormAI ensures that its personnel engaged in the processing of personal data are informed of the confidential nature of the personal data, have received appropriate training on their responsibilities and have agreed to confidentiality obligations that survive the termination of that persons' employment or engagement by ScormAI.
									</li>
									<li>
										ScormAI shall take commercially reasonable steps to ensure the reliability of any ScormAI personnel engaged in the processing of personal data and that access to personal data by ScormAI is limited to those ScormAI personnel who require such access to perform the Services.
									</li>
									<li>
										ScormAI's data protection officer can be reached by email at <a href="mailto:support@quizai.biz">support@quizai.biz</a>.
									</li>
								</ul>
							</li>
							<li>
								<h3>Sub-Processors</h3>
								<ul>
									<li>
										The Data Controller agrees ScormAI may engage third-party Sub-processors to provide the Services and such Sub-processors may access personal data, and appoint additional levels of Sub-processors, only for purposes of providing the services ScormAI retained them to provide and not for any other purpose.
									</li>
								</ul>
							</li>
							<li>
								<h3>Security</h3>
								<ul>
									<li>
										ScormAI agrees to implement and maintain the administrative, technical, and physical safeguards of personal data stored using the Services.
									</li>
								</ul>
							</li>
							<li>
								<h3>Security Breach Management and Notification</h3>
							</li>
							<ul>
								<li>
									If ScormAI becomes aware of unlawful access to the Data Controller's personal data stored through the Services, or unauthorized access to the Services resulting in loss, disclosure, or alteration of the Data Controller's personal data (“Security Breach”), ScormAI will promptly: (a) notify the Data Controller of the Security Breach; (b) investigate the Security Breach and provide the Data Controller with information known to ScormAI about the Security Breach; and (c) follow its policies and procedures to mitigate the effects and to minimize any damage resulting from the Security Breach.
								</li>
								<li>
									The Data Controller agrees that an unsuccessful Security Breach attempt will not be subject to Section 7.1 above. An unsuccessful Security Breach attempt is one that results in no unauthorized access to the Data Controller's personal data or to the Services storing your Personal Data, and may include, without limitation, pings and other broadcast attacks on firewalls or edge servers, port scans, unsuccessful log-on attempts, denial of service attacks, packet sniffing (or other unauthorized access to traffic data that does not result in access beyond IP addresses or headers) or similar incidents.
								</li>
								<li>
									Notification(s) of Security Breaches, if any, will be delivered to one or more of the Customer's business, technical or administrative contacts by any means ScormAI selects, including via email. It is Customer's sole responsibility to ensure it maintains accurate contact information on ScormAI´s support systems at all times.
								</li>
								<li>
									ScormAI's report of and/or response to a Security Breach under this Section will not be construed as an admission by ScormAI to fault or liability with respect to the Security Breach.
								</li>
							</ul>
							<li>
								<h3>Deletion of Customer Data</h3>
								<ul>
									<li>
										ScormAI agrees to delete Customer personal data in accordance with ScormAI's procedures and Data Protection Laws.
									</li>
									<li>
										At a Customer's request, ScormAI will provide the Customer with a certification of deletion of personal data.
									</li>
								</ul>
							</li>
							<li>
								<h3>Governing Laws</h3>
								<ul>
									<li>
										This Agreement is governed by the laws of Brazil.
									</li>
								</ul>
								<li>
									<h3>Legal Effect</h3>
									<ul>
										<li>
											This agreement comes into effect from the time of purchase of an ScormAI subscription. It expires with cessation of the Customer's ScormAI subscription.
										</li>
									</ul>
								</li>
							</li>
						</ol>
					</div>
				</div>
			</section>

			<div className={`row justify-content-center align-items-center ${styles.botoesChamadas} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
				<div className={`col-12 col-md-10 text-center`}>
					<h4>Additional resources:</h4>
					<a href="#" onClick={() => { goTerms() }} className={`${styles.btn} btn ${styles.btn_primary} mb-3 mb-md-0 me-md-3`}>Terms</a>
					<a href="#" onClick={() => { goPrivacy() }} className={`${styles.btn_primary} btn  mb-3 mb-md-0 me-md-3`}>Privacy</a>
					<a href="#" onClick={() => { goGdpr() }} className={`${styles.btn} btn ${styles.btn_primary}  mb-3 mb-md-0 me-md-3`}>GDPR</a>
				</div>
			</div>

			<div className={`${styles.scrollToTop}`} style={{ display: showScrollToTop ? 'block' : 'none' }}>
				{showScrollButton && (
					<img
						src={arrowLegal}
						alt="Arrow to the top"
						onClick={scrollToTop}
						style={{ cursor: 'pointer' }}
					/>
				)}
			</div>
		</div>
	)
}

export default DataProcessingAgreement