import React from 'react'
import { format } from 'date-fns';
import { pt, es, enUS } from 'date-fns/locale';
import styles from './PlanDetails.module.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PaddleSDK } from 'paddle-sdk';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useAuthentication } from '../hooks/useAuthentications';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { Timestamp } from 'firebase/firestore';
import { useLanguageValue } from '../context/LanguageContext';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import axios from 'axios';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

import DialogBox from './DialogBox';

const PlanDetail = ({
    userId,
    userDomainPlan,
    userTotalProjects,
    userTotalMonthProjects,
    userMonthProjectsLimit,
    userTotalDeletedProjects,
    userDeletedProjects,
    userNumberCC,
    userccOperator,
    userDomainStorage,
    userUsedStorage,
    userCancelUrl,
    userUpdateUrl,
    userNextBbirthDate,
    userPaddleId,
    userSubscriptionId,
    isActive,
    status,
    cancelDate,
    cancelEffectiveDate,
    couponCode,
    actionStatus
}) => {

    const navigate = useNavigate();
    const { logout } = useAuthentication();
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userId);

    const [showInfoProjects, setShowInfoProjects] = useState(false);
    const [showInfoStorage, setShowInfoStorage] = useState(false);
    const [isTrail, setIsTrail] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [dialogOption, setDialogOption] = useState([]);
    const [dialogShowLoading, setDialogShowLoading] = useState(false);
    const [updatingPlan, setUpdatingPlan] = useState(false);
    const [isPaddleButton, setIsPaddleButton] = useState(false);
    const [buttonsActive, setButtonsActive] = useState(false);
    const [statusName, setStatusName] = useState("");
    const [formattedPlan, setFormattedPlan] = useState("");
    const [paddle, setPaddle] = useState();
    const [transaction, setTransaction] = useState();
    const [textStatus, setTextStatus] = useState("");
    const [textButton, setTextButton] = useState("");
    const [cancelButton, setCancelButton] = useState("");
    const [actionCancelPaddle, setActionCancelPaddle] = useState(true);
    const [action, setAction] = useState("");

    const { t } = useTranslation(['myaccount']);
    const { preferenceLanguage } = useLanguageValue();

    const axios = require('axios');

    const reactivateSubscription = () => {
        setActionCancelPaddle(false)
        setAction("reactiveplan")
        setOpenDialog(true);
        const textMessage = t('myaccount:plandetails.Você quer reativar o plano');
        setMessage(textMessage);
        setDialogOption([t('myaccount:plandetails.Sim'), t('myaccount:plandetails.Não')]);
    }

    const reactivatePaddleSubscription = async (subscriptionId) => {
        console.log("ta chegando aqui: reactivatePaddleSubscription")
        setUpdatingPlan(true)
        paddle.Spinner.show()
        try {
            const response = await axios.patch(
                `https://us-central1-quizzai-4b3cd.cloudfunctions.net/patchRemoveCancelScheduled?subscriptionId=${subscriptionId}`
            );
        } catch (error) {
            console.error('Erro:', error);
        }
        paddle.Spinner.hide()

        setButtonsActive(true)
        setUpdatingPlan(false)
        setOpenDialog(true)
        const textMessage = t('myaccount:plandetails.Plano reativado');
        setMessage(textMessage);
        setDialogOption(["Ok"]);
    }

    const formattedDate = format(
        userNextBbirthDate,
        preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
        {
            locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
        }
    );

    const formattedCancelDate = cancelDate && format(
        cancelDate,
        preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
        {
            locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
        }
    );
    let formattedEffectiveDate;

    if (cancelEffectiveDate > Timestamp.now().toDate()) {
        formattedEffectiveDate = format(
            cancelEffectiveDate,
            preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
            {
                locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
            }
        );
    }

    const paddleClient = new PaddleSDK(
        process.env.REACT_APP_PADDLE_SELLER_ID,
        process.env.REACT_APP_PADDLE_API_KEY
    );

    const navigateCatalog = () => {
        navigate("/catalog")
    }

    const handleCancelPlan = () => {
        setAction("cancelplan")
        setActionCancelPaddle(true)
        setOpenDialog(true);
        const textMessage = t('myaccount:plandetails.deseja cancelar assinatura');
        setMessage(textMessage);
        setDialogOption([t('myaccount:plandetails.Sim'), t('myaccount:plandetails.Não')]);
    }

    const handleClosDialogBox = (f, option) => {
        if (option === "Sim" || option === "Yes" || option === "Si") {
            console.log("actionCancelPaddle: " + actionCancelPaddle)
            actionCancelPaddle ? cancelPaddlePlan(userSubscriptionId) : reactivatePaddleSubscription(userSubscriptionId);
        } else {
            setOpenDialog(false);
        }
    }

    const handleLinkClickChangePayment = async (event) => {
        console.log("handleLinkClickChangePayment")
        await getPaddleTransaction(userSubscriptionId);
    };

    const getPaddleTransaction = async (subscriptionId) => {
        paddle.Spinner.show()
        try {
            const response = await axios.get(
                `https://us-central1-quizzai-4b3cd.cloudfunctions.net/getExternalDataUpdatePayment?subscriptionId=${subscriptionId}`
            );

            setTransaction(response.data);
        } catch (error) {

            console.error('Erro:', error);
        }

        paddle.Spinner.hide()

    };

    const openCheckout = (tid) => {
        paddle.Setup({
            seller: 177179,

            checkout: {
                settings: {
                    displayMode: "overlay",
                    theme: "light",
                    locale: "en"
                }
            }
        });
        paddle.Checkout.open({
            transactionId: tid,
        });
    };

    useEffect(() => {
        if (transaction) {

            openCheckout(transaction.id)
        }

    }, [transaction])

    const cancelPaddlePlan = async (subscriptionId) => {
        const userInfos = {
            domain: userId,
            cancelled_by_user: true
        };

        const resDoc = await updateDocument(userInfos);

        setUpdatingPlan(true);

        paddle.Spinner.show();
        try {
            const response = await fetch(
                `https://us-central1-quizzai-4b3cd.cloudfunctions.net/postCancelSubscription?subscriptionId=${subscriptionId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ subscriptionId })
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
        } catch (error) {
            console.error('Erro:', error);
        }

        paddle.Spinner.hide();

        setUpdatingPlan(false);
        setOpenDialog(true);
        const textMessage = t('myaccount:plandetails.Assinatura cancelada');
        setMessage(textMessage);
        setDialogOption(["Ok"]);
    };


    const checkStatusName = (s) => {
        switch (s) {
            case "trial":
                setButtonsActive(false)
                setStatusName(t('myaccount:plandetails.Ativa'))
                break;
            case "expired":
                setButtonsActive(false)
                setStatusName(t('myaccount:plandetails.Expirada'))
                break;
            case "deleted":
                setButtonsActive(false)
                setStatusName(t('myaccount:plandetails.Cancelada'))
                break;
            case "paused":
                setButtonsActive(true)
                setStatusName(t('myaccount:plandetails.Pausada'))
                break;
            case "past_due":
                setButtonsActive(true)
                setStatusName(t('myaccount:plandetails.Pausada'))
                break;
            case "free":
                setButtonsActive(false)
                setStatusName(t('myaccount:plandetails.Ativa'))
                break;
            default:
                setButtonsActive(true)
                setStatusName(t('myaccount:plandetails.Ativa'))
        }
    }

    useEffect(() => {
        checkStatusName(status);
    }, [status])

    useEffect(() => {
        if (userDomainPlan && userDomainPlan.includes("trial")) {
            setIsTrail(true);
            setFormattedPlan("Trial");
        } else if (userDomainPlan && userDomainPlan === 'pro-monthly') {
            setFormattedPlan(t('myaccount:plandetails.Pro (mensal)'));
        } else if (userDomainPlan && userDomainPlan === 'pro-annual') {
            setFormattedPlan(t('myaccount:plandetails.Pro (anual)'));
        } else {
            setFormattedPlan("Basic (free)");;
        }
    }, [userDomainPlan])


    useEffect(() => {
        initializePaddle({ seller: 177179 }).then(
            (paddleInstance) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                }
            },
        );
    }, []);

    useEffect(() => {
        setTextButton(t('myaccount:plandetails.Trocar meu plano'))
        setCancelButton(true)
        if (actionStatus === "cancel") {
            setCancelButton(false)
            setTextButton(t('myaccount:plandetails.Reativar plano'))
            setButtonsActive(false)
            setTextStatus(t('myaccount:plandetails.Você cancelou esse plano', {
                formattedEffectiveDate: formattedEffectiveDate,
                interpolation: { prefix: '${', suffix: '}' }
            }))
        } else if (status) {
            status === "active" && setTextStatus(t('myaccount:plandetails.próxima data cobrança', {
                formattedDate: formattedDate,
                interpolation: { prefix: '${', suffix: '}' }
            }));
            status === "free" && setTextStatus(t(`myaccount:plandetails.Free prazo utilização ilimitado`));
            status === "canceled" && setTextStatus(t('myaccount:plandetails.Plano cancelado', {
                formattedDate: formattedDate,
                interpolation: { prefix: '${', suffix: '}' }
            }));
            status === "past_due" && setTextStatus(t('myaccount:plandetails.problemas com sua cobrança', {
                formattedDate: formattedDate,
                interpolation: { prefix: '${', suffix: '}' }
            }));
        } else {
            status === "active" && setTextStatus(t('myaccount:plandetails.próxima data de cobrança', {
                formattedDate: formattedDate,
                interpolation: { prefix: '${', suffix: '}' }
            }));
        }
    }, [status, actionStatus])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <div>
                <HelmetProvider>
                    {buttonsActive &&
                        <Helmet>
                            <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
                            <script>{`Paddle.Setup({ seller: 177179, })`}</script>
                        </Helmet>
                    }

                    {updatingPlan
                        ? <div className="d-flex justify-content-center mt-5">
                            <div className="align-self-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">{t(`myaccount:plandetails.Carregando`)}</span>
                                </div>
                            </div>
                        </div>
                        : <div className={``}>
                            <p></p>
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <h6>{t(`myaccount:plandetails.Seu plano atual`)} </h6>
                                </div>
                                <div className={`col-auto fw-semibold d-flex justify-content-center ${styles.round_pill} ${styles.text_plan}`}>
                                    {status === "active"
                                        ? formattedPlan
                                        : `${formattedPlan}`
                                    }
                                </div>
                                {couponCode && <div className="col-auto">
                                    <h6><span>{t(`myaccount:plandetails.Cupom`)} </span> <span><strong>{couponCode}</strong></span> <span> {t(`myaccount:plandetails.aplicado`)}</span></h6>

                                </div>}
                            </div>
                            <div className="mt-1">
                                {!isTrail
                                    ? <div>
                                        <h6>
                                            {textStatus && textStatus}
                                        </h6>

                                    </div>
                                    : <div>
                                        <div className="mt-2 col-auto">
                                            <h6>{t(`myaccount:plandetails.Licença para testes expira em`)} {formattedDate}</h6>
                                        </div>

                                    </div>
                                }
                            </div>
                            <hr className={`${styles.line}`} />

                            {isActive && <div className="pt-2 col-10">
                                <div className="row ms-5 d-flex align-items-center">
                                    <div className="col-5 d-flex justify-content-end">
                                        <h6>{t(`myaccount:plandetails.Total de projetos já realizados`)} </h6>
                                    </div>
                                    <div className="col-4 fw-bold">
                                        <h6>
                                            {userTotalProjects}
                                        </h6>
                                    </div>
                                </div>
                                <div className="row mt-1 ms-5 d-flex align-items-center">
                                    <div className="col-5 d-flex justify-content-end">
                                        <h6>{t(`myaccount:plandetails.Limite de projetos ativos por mês`)}</h6>
                                    </div>
                                    <div className="col-4 fw-bold">
                                        <div className={`progress ${styles.progress_customized}`}
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow={userTotalMonthProjects / userMonthProjectsLimit}
                                            aria-valuemin={0}
                                            aria-valuemax={100}>
                                            <div className="progress-bar" style={{ width: `${userTotalMonthProjects / userMonthProjectsLimit * 100}%`, backgroundColor: "#265287" }}></div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h6>{`${userTotalMonthProjects} / ${userMonthProjectsLimit}`}</h6>
                                    </div>
                                    <div onMouseOver={() => setShowInfoProjects(true)} onMouseLeave={() => setShowInfoProjects(false)} className="col-auto">
                                        <a >
                                            <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                        </a>
                                    </div>
                                    {showInfoProjects && <div className="col-5 mt-5" style={{ position: "absolute" }}>
                                        <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                            {t(`myaccount:plandetails.Um projeto ativo é`)}
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="row ms-5 mt-1 d-flex align-items-center">
                                    <div className="col-5 d-flex justify-content-end">
                                        <h6>{t(`myaccount:plandetails.Limite de armazenamento de dados`)}</h6>
                                    </div>
                                    <div className="col-4 fw-bold">
                                        <div className={`progress ${styles.progress_customized}`}
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow={userUsedStorage / userDomainStorage}
                                            aria-valuemin={0}
                                            aria-valuemax={100}>
                                            <div className="progress-bar" style={{ width: `${userUsedStorage / userDomainStorage * 100}%`, backgroundColor: "#265287" }}></div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h6>{`${userUsedStorage > 1000
                                            ? `${(userUsedStorage / 1000).toFixed(1)} Gb`
                                            : `${userUsedStorage.toFixed(1)} Mb`} / 
                                            ${userDomainStorage > 1000
                                                ? `${userDomainStorage / 1000} Gb`
                                                : `${userDomainStorage} Mb`} `}</h6>
                                    </div>
                                    <div onMouseOver={() => setShowInfoStorage(true)} onMouseLeave={() => setShowInfoStorage(false)} className="col-auto">
                                        <a >
                                            <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                        </a>
                                    </div>
                                    {showInfoStorage && <div className="col-5 mt-5" style={{ position: "absolute" }}>
                                        <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                            {t(`myaccount:plandetails.Todos os arquivos de um projeto permanecem armazenados no QuizAI`)}
                                        </div>
                                    </div>}
                                </div>
                            </div>}

                            <p></p>
                            <div className="row pt-3">
                                {buttonsActive && <div className="col-3 me-5">
                                    <button className={`${styles.btn_cancel}`} disabled={!buttonsActive} onClick={handleCancelPlan}><i className="bi bi-wallet2 me-2"></i> {t(`myaccount:plandetails.Cancelar plano atual`)}</button>
                                </div>}
                                <div className="col-3 me-5">
                                    <button onClick={cancelButton ? () => navigateCatalog() : () => reactivateSubscription()} className={`${styles.btn_change}`}><i className={`bi bi-x-octagon-fill me-2 `}></i> {textButton}</button>
                                </div>
                                {buttonsActive && <div className="col-3">
                                    <button className={`${styles.btn_style}`} disabled={!buttonsActive} onClick={() => handleLinkClickChangePayment()}><i className="bi bi-bag-plus-fill me-2"></i> {t(`myaccount:plandetails.Alterar forma de pagamento`)}</button>
                                    <a className="paddle_button update-subscribe w-100 d-none" data-override={userUpdateUrl}>{t(`myaccount:plandetails.Alterar forma de pagamento`)}</a>
                                </div>}
                            </div>
                            <p></p>
                            {openDialog && action && <DialogBox
                                isopen={true}
                                questionDialog={message}
                                optionsDialog={dialogOption}
                                actionScript={handleClosDialogBox}
                                action={action} />
                            }
                        </div>}
                </HelmetProvider>
            </div>
        </div>
    )
}

export default PlanDetail