import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import {collection, query, orderBy, onSnapshot} from "firebase/firestore";

export const useFetchProjects = (docCollection, search = null, uid = null) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;

    async function loadProjects() {
      if (!docCollection && !uid) {
        return;
      }

      setLoading(true);

      let collectionRef;

      if (docCollection === "Folder") {
        collectionRef = collection(db, "Domains", uid, "Folder");
      } else if (docCollection === "Plans" || docCollection === "Templates" || docCollection === "Companys") {
        collectionRef = collection(db, docCollection);
      } else {
        collectionRef = collection(
          db,
          "Domains",
          uid,
          "Folder",
          "subfolders",
          docCollection
        );
      }

      try {
        let q;

        if (docCollection !== "Plans" && docCollection !== "Templates" && docCollection !== "Companys") {
          q = query(collectionRef, orderBy("creation_date", "desc"));
        } else {
          q = collectionRef;
        }

        // Subscribe to real-time updates using onSnapshot
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          setDocuments(
            querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );
        });
      } catch (error) {
        setError(error.message);
      }

      setLoading(false);
    }

    loadProjects();

    // Clean up the snapshot listener when the component unmounts or when the inputs change.
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docCollection, search, uid]);

  return { documents, loading, error };
};
